<template>
    <div v-if="$router.currentRoute.name=='Capacitacion'">
        <v-container :style="$vuetify.breakpoint.smAndUp?'padding-top:100px':''">
            <v-row class="ma-0" justify="center">
                <v-col cols="12" align="center">
                    <v-card flat max-width="560px" color="transparent">
                        <h2 align="left">Fase 1</h2>
                        <iframe class="responsive-iframe" src="https://www.youtube.com/embed/DL402t28qIs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </v-card>
                </v-col>
                <v-col cols="12" align="center">
                    <v-card flat max-width="560px" color="transparent">
                        <h2 align="left">Fase 2</h2>
                        <iframe class="responsive-iframe" src="https://www.youtube.com/embed/1qD-VwF3PBs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </v-card>
                </v-col>
                <v-col cols="12" align="center">
                    <v-card flat max-width="560px" color="transparent">
                        <h2 align="left">Fase 3</h2>
                        <iframe class="responsive-iframe" src="https://www.youtube.com/embed/GqoUiZNRdlc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
.responsive-iframe {
    max-height: 315px;
    max-width: 560px;
    width: 100%;
    height: 315px;
}
</style>