<template>
    <div v-if="$router.currentRoute.name=='Informes'">
        <v-app>
            <v-container :style="$vuetify.breakpoint.smAndUp?'padding-top:100px':''">
                <v-row class="ma-0" justify="center">
                    <v-col v-for="(documento,index) in documentos.filter(item=>item.year.some(item2=>item2==$route.params.year))" :key="index" cols="12" sm="12" md="4" lg="3" align="center">
                        <v-card elevation="5" rounded="xl" max-width="560px" height="300" ripple :href="documento.path+documento.name" target="_blank">
                            <v-card-title class="justify-center text-md-subtitle-1 font-weight-bold">{{documento.name}}</v-card-title>
                            <br>
                            <v-card-text>

                            </v-card-text>
                            <v-card-actions>
                                <v-row class="pa-0" justify="center">
                                    <v-icon class="pa-0" color="black" size="75">download</v-icon>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
        </v-container>
        </v-app>
    </div>
</template>
<script>
export default {
    data:function(){
        return ({
            documentos:[
                {
                    path:"https://cdn.todossantoscuchumatan.gob.gt/sinacig/informes/",
                    name:"DIAGNOSTICO-4-COMPONENTES-31-ENERO (5).pdf",
                    year:[2021]
                },
                {
                    path:"https://cdn.todossantoscuchumatan.gob.gt/sinacig/informes/",
                    name:"INFORME DE CONTROL INTERNO TODOS SANTOS.pdf",
                    year:[2021]
                },
                {
                    path:"https://cdn.todossantoscuchumatan.gob.gt/sinacig/informes/2022/",
                    name:"INFORME ANUAL DE CONTROL INTERNO AÑO 2022.pdf",
                    year:[2022]
                }
            ]
        })
    }
}
</script>
<style scoped>

</style>