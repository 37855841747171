<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1>Boleto de ornato</h1>
                </header>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
export default {
  components: {  Header, SocialLinks },
    
}
</script>
<style scoped>

</style>