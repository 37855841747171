<template>
    <v-app id="documentos_publicos">
        <v-row v-if="!isLoaded" class="pa-0 ma-0" justify="center" align="center">
            <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                <v-progress-linear :color="$store.state.accentColor" indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-dialog v-model="deleteDocumentDialog" max-width="300">
            <v-card :loading="savingLoader" :disabled="savingLoader">
                <v-card-title>
                    Confirmación
                </v-card-title>
                <v-card-text>
                    ¿Desea eliminar el documento "{{getDocumentoName}}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDocumentDialog=!deleteDocumentDialog">Cancelar</v-btn>
                    <v-btn text @click="
                    ()=>{
                        if(selectedDecreto.idDecreto!=16){
                            deleteFile(selectedFile.idDecretoCategoriaPdf,selectedFile.urlPdf)
                        }else{
                            deleteFileNoDecreto(selectedFile.idCategoriaPdf,selectedFile.urlPdf)
                        }
                    }
                    ">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteCategoriaDialog" max-width="300">
            <v-card :loading="savingLoader" :disabled="savingLoader">
                <v-card-title>
                    Confirmación
                </v-card-title>
                <v-card-text>
                    ¿Desea eliminar el inciso "{{getCategoriaName}}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="()=>{deleteCategoriaDialog=false}">Cancelar</v-btn>
                    <v-btn text @click="()=>{
                            if(selectedDecreto.idDecreto!=16){
                                deleteCategoriaCall()
                            }else{
                                deleteCategoriaCallNoDecreto()
                            }
                        }">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDecretoDialog" max-width="300">
            <v-card :loading="savingLoader" :disabled="savingLoader">
                <v-card-title>
                    Confirmación
                </v-card-title>
                <v-card-text>
                    ¿Desea eliminar el decreto "{{getDecretoName}}"?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="()=>{deleteDecretoDialog=false}">Cancelar</v-btn>
                    <v-btn text @click="()=>{deleteDecretoCall()}">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="newDecretoDialog" persistent max-width="400">
            <v-card :loading="savingLoader" :disabled="savingLoader">
                <v-card-title>
                    Nuevo decreto
                </v-card-title>
                <v-card-text>
                    <v-form ref="newDecretoForm">
                        <br>
                        <v-text-field 
                        autocomplete="off"
                        class="hola"
                        :color="$store.state.accentColor"
                        label="Nombre decreto"
                        v-model="newDecretoForm.decreto"
                        :rules="newDecretoForm.decretoRules"
                        counter="100"
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="newDecretoDialog=!newDecretoDialog">Cancelar</v-btn>
                    <v-btn text @click="()=>{saveDecreto()}">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="newCategoriaDialog" persistent max-width="400">
            <v-card :loading="savingLoader" :disabled="savingLoader">
                <v-card-title>
                    Nuevo inciso
                </v-card-title>
                <v-card-text>
                    <v-form ref="newCategoriaForm">
                        <br>
                        <v-text-field 
                        :color="$store.state.accentColor"
                        autocomplete="off"
                        label="Nombre de inciso"
                        v-model="newCategoriaForm.categoria"
                        :rules="newCategoriaForm.categoriaRules"
                        counter="150"
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="newCategoriaDialog=!newCategoriaDialog">Cancelar</v-btn>
                    <v-btn text @click="()=>{
                            if(selectedDecreto.idDecreto==16){
                                saveCategoryNoDecreto();
                            }else{
                                saveCategory();
                            }
                        }">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        v-model="uploadFilesDialog"
        max-width="500"
        scrollable
        persistent
        >
            <v-card :loading="savingLoader" :disabled="savingLoader">
                <v-card-title class="headline">Documentos</v-card-title>
                <v-card-text>
                    <v-row class="ma-0 py-1"/>
                    Aquí podrá elegir los documentos que agregará en el inciso
                    "{{getCategoriaName}}" del decreto "{{getDecretoName}}"  en {{month}} - {{year}}.
                    <br>Puede elegir multiples documentos a la vez.
                    <v-form>
                        <v-file-input 
                            prepend-icon=""
                            show-size counter 
                            multiple label="Seleccione archivos"
                            v-model="filesToUpload"
                            accept=".pdf"
                            :color="$store.state.accentColor"
                            :clearable="false"
                        ></v-file-input>
                        <br>
                        <v-row v-for="(fileInput,index) in filesToUpload" :key="index"
                            class="pa-0 ma-0">
                            <v-col cols="12" sm="8" md="8" class="py-0">
                                <v-text-field
                                    label="Descripción (Opcional)"
                                    :color="$store.state.accentColor"
                                    v-model="fileInput.description"
                                    autocomplete="off"
                                >

                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4" class="py-0 caption">
                                {{fileInput.name}}
                                
                                <v-row class="pa-0 ma-0" justify="end">
                                    <v-btn icon @click="removeFile(index)">
                                        <v-icon>
                                            clear
                                        </v-icon>
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="uploadFilesDialog = false"
                >
                    Cancelar
                </v-btn>

                <v-btn
                    :disabled="filesToUpload.length==0"
                    text
                    @click="()=>{
                            if(selectedDecreto.idDecreto==16){
                                uploadFilesNoDecreto();
                            }else{
                                uploadFiles();
                            }
                        }"
                >
                    Guardar
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-tabs centered v-model="typeTab">
            <v-tab key="#anualmensual">Anual-mensual</v-tab>
            <v-tab key="#anual">Anual</v-tab>

            <v-tab-item key="anualmensual">
                <v-container fluid class="pa-0 ma-0">
                    <!--<v-row justify="center">
                        <v-radio-group>
                            <v-radio label="Anual-mensual"></v-radio>
                            <v-radio label="Anual"></v-radio>
                        </v-radio-group>
                    </v-row>-->
                    <v-row  class="pa-0 ma-0" justify="center">
                        <v-col cols="6" sm="4" md="2" lg="2" xl="2">
                            <v-select
                            :item-color="$store.state.accentColor"
                            :color="$store.state.accentColor"
                            :items="years"
                            v-model="year"
                            label="Año"
                            solo
                            @change="onYearChange"
                            ></v-select>
                        </v-col>
                        <v-col cols="6" sm="4" md="2" lg="2" xl="2">
                            <v-select
                            :item-color="$store.state.accentColor"
                            :color="$store.state.accentColor"
                            :items="months"
                            v-model="month"
                            label="Mes"
                            solo
                            @change="onMonthChange"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="center" class="text-h6">Decretos</v-row>
                    <v-row v-if="isLoaded" class="pa-0 ma-0">
                        <v-col v-for="(decreto,index) in decretos" :key="index" cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                            <v-card outlined>
                                <v-card-title class="pb-0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-btn v-on="on" v-if="$store.state.UserLevel=='1'" @click="deleteDecreto(decreto)" small icon class="add_files_button"><v-icon>clear</v-icon></v-btn>
                                        </template>
                                        <span>
                                            Eliminar decreto
                                        </span>
                                    </v-tooltip>
                                    {{decreto.decreto}}
                                </v-card-title>
                                <v-card-text>
                                    <p align="center" class="body-1 font-weight-bold">Incisos {{month+' '+year}}</p>
                                    <v-expansion-panels v-for="(categoria,index2) in decreto.categorias" :key="index2" class="pa-0 pb-3 ma-0" flat>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="wrap-text">{{categoria.categoria}}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <div class="py-1"></div>
                                                <v-row no-gutters justify="center" align="center">
                                                    <v-btn x-small text  @click="showSelectFilesDialog(categoria,decreto)">Agregar documento</v-btn>
                                                    <div class="px-1"></div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on}">
                                                            <v-btn v-if="$store.state.UserLevel=='1'" icon v-on="on" small @click="deleteCategoria(categoria,decreto)"><v-icon>clear</v-icon></v-btn>
                                                        </template>
                                                        <span>
                                                            Eliminar inciso
                                                        </span>
                                                    </v-tooltip>
                                                </v-row>
                                                <br>
                                                <v-row no-gutters class="text-subtitle-2 font-weight-bold" justify="center">Documentos</v-row>
                                                <v-list class="pa-0">
                                                    <v-list-item @click="()=>{}" dense v-for="(pdf,index3) in categoria.pdfs" :key="index3" class="pa-0 px-2 ma-0">
                                                        {{pdf.descripcion=="undefined"?"***":pdf.descripcion}}
                                                        <v-spacer />
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                text
                                                                x-small
                                                                v-on="on"
                                                                >
                                                                Opciones
                                                                </v-btn>
                                                            </template>
                                                            <v-list dense class="pa-0">
                                                                <v-list-item :href="pdf.urlPdf" target="_blank">
                                                                    <v-list-item-title>Descargar</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click="deleteDocumentDialog=true;selectedFile=pdf;selectedDecreto=decreto;" v-if="$store.state.UserLevel=='1'">
                                                                    <v-list-item-title>Eliminar</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </v-list-item>
                                                </v-list>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-btn small v-on="on" fab @click="()=>{
                                                onaddCategoria(decreto)
                                            }
                                            "><v-icon>add</v-icon></v-btn>
                                        </template>
                                        <span>
                                            Agregar inciso
                                        </span>
                                    </v-tooltip>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
            <v-tab-item>
                <v-container fluid class="pa-0 ma-0">
                    <v-row  class="pa-0 ma-0" justify="center">
                        <v-col cols="12" sm="4" md="2" lg="2" xl="2">
                            <v-select
                            :item-color="$store.state.accentColor"
                            :color="$store.state.accentColor"
                            :items="years"
                            v-model="year"
                            label="Año"
                            solo
                            @change="onYearChange"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="center" class="text-h6">Decretos</v-row>
                    <v-row v-if="isLoaded" class="pa-0 ma-0">
                        <v-col v-for="(decreto,index) in decretos" :key="index" cols="12" xs="12" sm="12" md="6" lg="6" xl="4">
                            <v-card outlined>
                                <v-card-title class="pb-0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-btn v-on="on" v-if="$store.state.UserLevel=='1'" @click="deleteDecreto(decreto)" small icon class="add_files_button"><v-icon>clear</v-icon></v-btn>
                                        </template>
                                        <span>
                                            Eliminar decreto
                                        </span>
                                    </v-tooltip>
                                    {{decreto.decreto}}
                                </v-card-title>
                                <v-card-text>
                                    <p align="center" class="body-1 font-weight-bold">Incisos {{year}}</p>
                                    <v-expansion-panels v-for="(categoria,index2) in decreto.categorias" :key="index2" class="pa-0 pb-3 ma-0" flat>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="wrap-text">{{categoria.categoria}}</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <div class="py-1"></div>
                                                <v-row no-gutters justify="center" align="center">
                                                    <v-btn x-small text  @click="showSelectFilesDialog(categoria,decreto)">Agregar documento</v-btn>
                                                    <div class="px-1"></div>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{on}">
                                                            <v-btn v-if="$store.state.UserLevel=='1'" icon v-on="on" small @click="deleteCategoria(categoria,decreto)"><v-icon>clear</v-icon></v-btn>
                                                        </template>
                                                        <span>
                                                            Eliminar inciso
                                                        </span>
                                                    </v-tooltip>
                                                </v-row>
                                                <br>
                                                <v-row no-gutters class="text-subtitle-2 font-weight-bold" justify="center">Documentos</v-row>
                                                <v-list class="pa-0">
                                                    <v-list-item @click="()=>{}" dense v-for="(pdf,index3) in categoria.pdfs" :key="index3" class="pa-0 px-2 ma-0">
                                                        {{pdf.descripcion=="undefined"?"***":pdf.descripcion}}
                                                        <v-spacer />
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn
                                                                text
                                                                x-small
                                                                v-on="on"
                                                                >
                                                                Opciones
                                                                </v-btn>
                                                            </template>
                                                            <v-list dense class="pa-0">
                                                                <v-list-item :href="pdf.urlPdf" target="_blank">
                                                                    <v-list-item-title>Descargar</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item @click="deleteDocumentDialog=true;selectedFile=pdf;selectedDecreto=decreto;" v-if="$store.state.UserLevel=='1'">
                                                                    <v-list-item-title>Eliminar</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </v-list-item>
                                                </v-list>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{on}">
                                            <v-btn small v-on="on" fab @click="()=>{
                                                onaddCategoria(decreto)
                                            }
                                            "><v-icon>add</v-icon></v-btn>
                                        </template>
                                        <span>
                                            Agregar inciso
                                        </span>
                                    </v-tooltip>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
        </v-tabs>
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <v-btn
                    :color="$store.state.accentColor"
                    v-on="on"
                    fixed
                    fab
                    bottom
                    right
                    @click="newDecretoDialog=true;"
                >
                    <v-icon  color="white">add</v-icon>
                </v-btn>
            </template>
            <span>Agregar decreto</span>
        </v-tooltip>
        <v-snackbar
            v-model="snackbarObject.snackbar"
            :color="snackbarObject.snackbarColor"
        >
            {{ snackbarObject.snackbarText }}
        </v-snackbar>
    </v-app>
</template>
<script>
export default {
    watch:{
        typeTab(newval,oldval){
            oldval;
            this.loadDocuments(this.year,this.months.indexOf(this.month)+1,newval);
        },
        newCategoriaDialog(newval,oldval){
            oldval;

            if(!newval){
                this.$refs.newCategoriaForm.reset();
            }
        },
        newDecretoDialog(newval,oldval){
            oldval;

            if(!newval){
                this.$refs.newDecretoForm.reset();
            }
        }
    },
    computed:{
        getDocumentoName(){
            if(this.selectedFile){
                if(this.selectedFile.descripcion)
                    return this.selectedFile.descripcion;
            }
            return "***";
        },
        getCategoriaName(){
            if(this.selectedCategory){
                return this.selectedCategory.categoria;
            }
            return "";
        },
        getDecretoName(){
            if(this.selectedDecreto){
                return this.selectedDecreto.decreto;
            }
            return "";
        }
    },
    data:()=>({
        typeTab:null,
        isLoaded:false,
        years:[],
        newCategoriaDialog:false,
        newDecretoDialog:false,
        deleteDocumentDialog:false,
        deleteDecretoDialog:false,
        deleteCategoriaDialog:false,
        months:[
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        year:new Date().getFullYear(),
        month:"",
        categories:[],
        uploadFilesDialog:false,
        savingLoader:false,
        filesToUpload:[],
        selectedDecreto:Object,
        selectedCategory:Object,
        selectedFile:Object,
        snackbarObject:{
            snackbar:false,
            snackbarText:"",
            snackbarColor:"",
        },
        newCategoriaForm:{
            categoria:"",
            categoriaRules:[
                v => !!v || 'Nombre categoría es requerido',
                v => (v && v.length <= 150) || 'Nombre categoría debe ser menor o igual a 150',
            ],
        },
        newDecretoForm:{
            decreto:"",
            decretoRules:[
                v => !!v || 'Nombre decreto es requerido',
                v => (v && v.length <= 100) || 'Nombre decreto debe ser menor o igual a 100',
            ],
        },
        decretos:[],
        decretosFiltro:[]
    }),
    methods:{
        deleteCategoria(categoria,decreto){
            this.selectedCategory=categoria;
            this.selectedDecreto=decreto;

            this.deleteCategoriaDialog=true;
        },
        deleteDecreto(decreto){
            this.selectedDecreto=decreto;
            this.deleteDecretoDialog=true;
        },
        onaddCategoria(decreto){
            this.selectedDecreto=decreto;
            this.newCategoriaDialog=true;
        },
        removeFile(index){
            this.filesToUpload.splice(index,1);
        },
        showSelectFilesDialog(category,decreto){
            this.selectedCategory=category;
            this.selectedDecreto=decreto;

            this.uploadFilesDialog=!this.uploadFilesDialog;
        },
        onYearChange(anio){
            this.loadDocuments(anio,this.months.indexOf(this.month)+1,this.typeTab)
        },
        onMonthChange(mes){
            this.loadDocuments(this.year,this.months.indexOf(mes)+1,this.typeTab)
        },
        async loadDocuments(anio,mes,tipo){
            this.isLoaded=false;

            await this.$axios.get(
                `decretos/Decretos.php?anio=${anio}&month=${mes}&type=${tipo}`
            ).then(response=>{
                if(response.status==200){
                    if(response.data.code==141){
                        this.decretos=response.data.data;
                        //this.filterAnualMensual(this.typeTab);
                        //this.decretos=this.decretos.filter(item=>item.idDecreto!=16);
                    }
                }
            }).catch(error=>{
                if(error.response!=null){
                    if(error.response.status==404){
                        if(error.response.data!=null){
                            if(error.response.data.code==29||error.response.data.code==25){
                                this.$cookies.remove("AUTH_TOKEN");
                                this.$router.replace({name:"Login"});
                            }
                        }
                    }   
                }
                this.showSnackbar(`Ha ocurrido un error grave al cargar los documentos, si el error persiste contacte a soporte.`,"red");
            });

            this.isLoaded=true;
        },
        async uploadFilesNoDecreto(){
            if(this.filesToUpload){
                let formData=new FormData();

                
                formData.append("categoria",this.selectedCategory.idCategoria);
                formData.append("anio",this.year);
                formData.append("mes",this.months.indexOf(this.month)+1);

                for(let ix=0;ix<this.filesToUpload.length;ix++){
                    formData.append(ix,this.filesToUpload[ix]);
                    formData.append(ix,this.filesToUpload[ix].description);
                }

                this.savingLoader=this.$store.state.accentColor;
                await this.$axios.post(
                    "categoriaspdf/Create.php",
                    formData
                ).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 820:
                                this.filesToUpload=[];
                                this.showSnackbar("Ese documento ya existe en este inciso.");

                                break;
                            case 821:
                                this.filesToUpload=[];
                                this.showSnackbar("Documentos subidos correctamente a este inciso","green");

                                this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);

                                break;
                        }
                    }
                }).catch(error=>{
                    error;
                    this.showSnackbar(`Ha ocurrido un error grave al guardar los documentos, si el error persiste contacte a soporte.`,"red");
                });
            }

            this.savingLoader=false;
            this.uploadFilesDialog=false;
        },
        async deleteFileNoDecreto(idCategoriasPdf,filename){
            let splittedUrl=filename.split("/");
            let realFileName=splittedUrl[splittedUrl.length-1];
            let monthNumber=this.months.indexOf(this.month)+1;

            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.delete(
                `categoriaspdf/Delete.php?anio=${this.year}&mes=${monthNumber}&categoriapdf=${idCategoriasPdf}`,
                {
                    data:{ "filename":realFileName},
                }
            ).then(response=>{
                if(response.status==200){
                    switch (response.data.code) {
                        case 830:
                            this.showSnackbar(`El documento ya ha sido eliminado.`,"");

                            break;
                        case 831:
                            this.showSnackbar(`Documento eliminado correctamente.`,"green");
                            
                            break;
                    }

                    this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);
                }
            }).catch(error=>{
                if(error.response!=null){
                    if(error.response.status==404){
                        if(error.response.data!=null){
                            if(error.response.data.code==29||error.response.data.code==25){
                                this.$cookies.remove("AUTH_TOKEN");
                                this.$router.replace({name:"Login"});
                            }
                        }
                    }   
                }
                this.showSnackbar(`Ha ocurrido un error grave al eliminar el documento, si el error persiste contacte a soporte.`,"red");
            });

            this.savingLoader=false;
            this.deleteDocumentDialog=false;
        },
        async deleteCategoriaCallNoDecreto(){
            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.delete(`categorias/Delete.php?categoria=${this.selectedCategory.idCategoria}`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 841:{
                            this.showSnackbar("Inciso eliminado correctamente","green");
                        }break;
                        case 842:case 840:{
                            this.showSnackbar(`Ha ocurrido un error al eliminar el inciso, si el error persiste contacte a soporte.`,"");
                        }break;
                    }
                }
            }).catch((error)=>{
                if(error.response!=null){
                    if(error.response.status==404){
                        if(error.response.data!=null){
                            if(error.response.data.code==29||error.response.data.code==25){
                                this.$cookies.remove("AUTH_TOKEN");
                                this.$router.replace({name:"Login"});
                            }
                        }
                    }   
                }
                this.showSnackbar(`Ha ocurrido un error grave al eliminar el inciso, si el error persiste contacte a soporte.`,"red");
            });

            this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);
            this.deleteCategoriaDialog=false;
            this.savingLoader=false;
        },
        async saveCategoryNoDecreto(){
            
            if(this.$refs.newCategoriaForm.validate()){
                if(this.typeTab==1)
                    this.newCategoriaForm.anual=true;
                else
                    delete this.newCategoriaForm.anual;

                this.savingLoader=this.$store.state.accentColor;
                await this.$axios.put(`categorias/Create.php`,
                    this.newCategoriaForm
                ).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 821:{
                                this.showSnackbar("Inciso agregado correctamente","green");
                            }break;
                            case 820:{
                                this.showSnackbar(`Ha ocurrido un error al guardar el inciso, si el error persiste contacte a soporte.`,"");
                            }break;
                        }
                    }
                }).catch(error=>{
                    if(error.response!=null){
                        if(error.response.status==404){
                            if(error.response.data!=null){
                                if(error.response.data.code==29||error.response.data.code==25){
                                    this.$cookies.remove("AUTH_TOKEN");
                                    this.$router.replace({name:"Login"});
                                }
                            }
                        }   
                    }
                    this.showSnackbar(`Ha ocurrido un error grave al guardar el inciso, si el error persiste contacte a soporte.`,"red");
                });

                this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);
                this.savingLoader=false;
                this.newCategoriaDialog=false;
            }
        },
        async uploadFiles(){
            if(this.filesToUpload){
                let formData=new FormData();

                
                formData.append("decretoCategoria",this.selectedCategory.idDecretoCategoria);
                formData.append("anio",this.year);
                formData.append("mes",this.months.indexOf(this.month)+1);

                for(let ix=0;ix<this.filesToUpload.length;ix++){
                    formData.append(ix,this.filesToUpload[ix]);
                    formData.append(ix,this.filesToUpload[ix].description);
                }

                this.savingLoader=this.$store.state.accentColor;
                await this.$axios.post(
                    "decretoscategoriaspdf/Create.php",
                    formData
                ).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 820:
                                this.filesToUpload=[];
                                this.showSnackbar("Ese documento ya existe en ésta categoría.");

                                break;
                            case 821:
                                this.filesToUpload=[];
                                this.showSnackbar("Documentos subidos correctamente a ésta categoría","green");

                                this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);

                                break;
                        }
                    }
                }).catch(error=>{
                    error;
                    this.showSnackbar(`Ha ocurrido un error grave al guardar los documentos, si el error persiste contacte a soporte.`,"red");
                });
            }

            this.savingLoader=false;
            this.uploadFilesDialog=false;
        },
        async deleteFile(idDecretoCategoriaPdf,filename){
            let splittedUrl=filename.split("/");
            let realFileName=splittedUrl[splittedUrl.length-1];
            let monthNumber=this.months.indexOf(this.month)+1;

            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.delete(
                `decretoscategoriaspdf/Delete.php?anio=${this.year}&mes=${monthNumber}&decreto-categoriapdf=${idDecretoCategoriaPdf}`,
                {
                    data:{ "filename":realFileName},
                }
            ).then(response=>{
                if(response.status==200){
                    switch (response.data.code) {
                        case 830:
                            this.showSnackbar(`El documento ya ha sido eliminado.`,"");

                            break;
                        case 831:
                            this.showSnackbar(`Documento eliminado correctamente.`,"green");
                            
                            break;
                    }

                    this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);
                }
            }).catch(error=>{
                error;
                this.showSnackbar(`Ha ocurrido un error grave al eliminar el documento, si el error persiste contacte a soporte.`,"red");
            });

            this.savingLoader=false;
            this.deleteDocumentDialog=false;
        },
        async deleteCategoriaCall(){
            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.delete(`decretoscategorias/Delete.php?decreto-categoria=${this.selectedCategory.idDecretoCategoria}`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 841:{
                            this.showSnackbar("Inciso de decreto eliminado correctamente","green");
                        }break;
                        case 842:case 840:{
                            this.showSnackbar(`Ha ocurrido un error al eliminar el inciso del decreto, si el error persiste contacte a soporte.`,"");
                        }break;
                    }
                }
            }).catch((error)=>{
                error;
                this.showSnackbar(`Ha ocurrido un error grave al eliminar el inciso del decreto, si el error persiste contacte a soporte.`,"red");
            });

            this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);
            this.deleteCategoriaDialog=false;
            this.savingLoader=false;
        },
        async saveCategory(){
            
            if(this.$refs.newCategoriaForm.validate()){

                if(this.typeTab==1)
                    this.newCategoriaForm.anual=true;
                else
                    delete this.newCategoriaForm.anual;

                this.savingLoader=this.$store.state.accentColor;
                await this.$axios.put(`decretoscategorias/Create.php?decreto=${this.selectedDecreto.idDecreto}`,
                    this.newCategoriaForm
                ).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 821:{
                                this.showSnackbar("Inciso agregado correctamente","green");
                            }break;
                            case 820:{
                                this.showSnackbar(`Ha ocurrido un error al guardar el inciso, si el error persiste contacte a soporte.`,"");
                            }break;
                        }
                    }
                }).catch(error=>{
                    error;
                    this.showSnackbar(`Ha ocurrido un error grave al guardar el inciso, si el error persiste contacte a soporte.`,"red");
                });

                this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);
                this.savingLoader=false;
                this.newCategoriaDialog=false;
            }
        },
        async deleteDecretoCall(){
            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.delete(`decretos/Delete.php?decreto=${this.selectedDecreto.idDecreto}`).then((response)=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 841:{
                            this.showSnackbar("Decreto agregado correctamente","green");
                        }break;
                        case 842:case 840:{
                            this.showSnackbar(`Ha ocurrido un error al eliminar el decreto, si el error persiste contacte a soporte.`,"");
                        }break;
                    }
                }
            }).catch((error)=>{
                error;
                this.showSnackbar(`Ha ocurrido un error grave al eliminar el decreto, si el error persiste contacte a soporte.`,"red");
            });

            this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);
            this.deleteDecretoDialog=false;
            this.savingLoader=false;
        },
        async saveDecreto(){
            
            if(this.$refs.newDecretoForm.validate()){

                this.savingLoader=this.$store.state.accentColor;
                await this.$axios.post(
                    "decretos/Create.php",this.newDecretoForm
                ).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 821:{
                                this.showSnackbar("Decreto agregado correctamente","green");

                                this.$refs.newDecretoForm.reset();
                            }break;
                            case 822:case 820:{
                                this.showSnackbar(`Ha ocurrido un error al guardar el decreto, si el error persiste contacte a soporte.`,"");
                            }break;
                        }
                    }
                }).catch(error=>{
                    error;
                    this.showSnackbar(`Ha ocurrido un error grave al guardar el decreto, si el error persiste contacte a soporte.`,"red");
                });

                this.loadDocuments(this.year,this.months.indexOf(this.month)+1,this.typeTab);

                this.savingLoader=false;
                this.newDecretoDialog=false;
            }
        },
        showSnackbar(text,color){
            this.snackbarObject.snackbar=false;
            
            this.snackbarObject.snackbarText=text;
            
            this.snackbarObject.snackbarColor=color;
            this.snackbarObject.snackbar=true;
        },
    },
    created(){
        for(var year=2020;year<=new Date().getFullYear();year++){
            this.years.push(year);
        }

        this.month=this.months[new Date().getMonth()];
    }
}
</script>
<style scoped>
    .add_files_button{
        position:absolute;
        right:8px;
        top:8px;
        z-index:2;
    }

    .not_wide{
        max-width: 60%;
    }
</style>