<template>
    <div class="inner">
    <!-- Header -->
        
        <Header/>
    <!-- Content -->
        <section>
            <header class="main">
                <h1>Comunícate</h1>
            </header>
            <v-row no-gutters justify="center">
                <v-col cols="12" sm="12" md="10" lg="8" xl="6">
                    <v-app>
                        <v-card :loading="isLoading" :disabled="isLoading" flat>
                            <v-card-text>
                                <v-form ref="contacto">
                                    <div class="row gtr-uniform">
                                        <div class="col-6 col-12-xsmall">
                                            <input v-model="contactoForm.nombre" type="text" placeholder="Nombre" />
                                        </div>
                                        <div class="col-6 col-12-xsmall">
                                            <input type="email" v-model="contactoForm.email" placeholder="Correo electrónico" />
                                        </div>
                                        <!-- Break -->
                                        <div class="col-12">
                                            <select v-model="contactoForm.selectedMotivo" name="demo-category" id="demo-category">
                                                <option value="">Seleccione motivo</option>
                                                <option value="0">Denuncia</option>
                                                <option value="1">Solicitud de información</option>
                                            </select>
                                        </div>
                                        <!-- Break -->
                                        <div class="col-12">
                                            <textarea v-model="contactoForm.mensaje" name="demo-message" id="demo-message" placeholder="Ingrese su mensaje" rows="6"></textarea>
                                        </div>
                                        <!-- Break -->
                                        <transition name="fade-transition">
                                            <v-row no-gutters v-if="validationMessage.show" justify="center">
                                                <v-card class="pa-0 rounded-lg" flat :color="validationMessage.color" dark max-width="300">
                                                    <v-row no-gutters class="text-caption pa-2">{{validationMessage.message}}</v-row>
                                                </v-card>
                                            </v-row>
                                        </transition>
                                        <div class="col-12">
                                            <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" sitekey="6Ldg100bAAAAAJ0VilSE8h58IeFeSgKB0iWRouqH" :loadRecaptchaScript="true"></vue-recaptcha>
                                            <br>
                                            <ul class="actions pa-0">
                                                <li  v-if="isVerified"><input type="button" @click="onsendContacto" value="Enviar mensaje" class="primary"></li>
                                                <li><input type="reset" value="Resetear" @click="onReset"></li>
                                            </ul>
                                        </div>
                                    </div>
                                </v-form>
                                <transition name="fade-transition">
                                    <v-row no-gutters v-if="customMessage.show" justify="center">
                                        <v-card class="pa-0 rounded-lg" flat :color="customMessage.color" dark max-width="300">
                                            <v-row no-gutters class="text-subtitle-1 pa-3">{{customMessage.message}}</v-row>
                                        </v-card>
                                    </v-row>
                                </transition>
                            </v-card-text>
                        </v-card>    
                    </v-app>
                </v-col>
            </v-row>
        </section>
        <section>
            <header>
                <div class="icons">
                    <social-links></social-links>
                </div>
            </header>
        </section>
    </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import Header from '../components/Header.vue'
import SocialLinks from '../components/SocialLinks.vue'
export default {
  components: { Header, SocialLinks,VueRecaptcha },
  data:function(){
      return ({
            isLoading:false,
            isVerified:false,
            recaptchaToken:"",
            motivoItems:[
                "Denuncia",
                "Solicitud de Información"
            ],
            customMessage:{
                show:false,
                message:"",
                color:""
            },
            validationMessage:{
                show:false,
                message:"",
                color:"red"
            },
            contactoForm:{
                selectedMotivo:"",
                selectedMotivoRules:[v => !!v || 'Motivo es requerido'],
                nombre:"",
                nombreRules:[
                    v => !!v || 'Nombre es requerido',
                    v => (v && v.length <= 50) || 'Nombre debe ser menor o igual a 50',
                ],
                email:"",
                emailRules:[
                    v => !!v || 'Correo electrónico es requerido',
                    v => /.+@.+\..+/.test(v) || 'Correo electrónico debe ser valido',
                ],
                mensaje: "",
                mensajeRules: [
                    v => !!v || 'Mensaje es requerido',
                    v => (v && v.length <= 50) || 'Mensaje debe ser menor o igual a 250 caracteres',
                ],
            },
      })
  },
    methods:{
        async onsendContacto(){
            if(!this.isValidContacto()){
                return;
            }

            let dataContacUs={
                recaptchaToken:this.recaptchaToken,
                motivo:this.motivoItems.indexOf(this.contactoForm.selectedMotivo),
                nombre:this.contactoForm.nombre,
                email:this.contactoForm.email,
                mensaje:this.contactoForm.mensaje
            };

            this.isLoading=this.$store.state.accentColor;
            await this.$axios.post(`contactenos/contacto.php`,dataContacUs).then(response=>{
                if(response.status==200){
                    if(response.data.code==621){
                        this.resetForm();

                        this.customMessage.color=this.$store.state.accentColor;
                        this.customMessage.message="¡Mensaje enviado!";

                    }else{
                        this.customMessage.color="black";
                        this.customMessage.message="Ha ocurrido un error y no se envió el mensaje";
                    }

                    this.onReset();
                    this.showMessage(this.customMessage);
                }
            }).catch(error=>{
                error;
                console.log(error);
            });
            this.isLoading=false;
        },
        onVerify(val){
            if(val){
                this.isVerified=true;
                this.recaptchaToken=val;
            }
        },
        onExpired(){
            this.isVerified=false;
        },
        onReset(){
            this.$refs.recaptcha.reset();
            this.isVerified=false;
        },
        resetForm(){
            this.contactoForm.selectedMotivo="";
            this.contactoForm.nombre="";
            this.contactoForm.email="";
            this.contactoForm.mensaje="";
        },
        showMessage(toShow){
            toShow.show=true;
            setTimeout(()=>{
                toShow.show=false;
            },3000);
        },
        isValidContacto(){
            var valid=false;
            if(!this.contactoForm.nombre){

                this.validationMessage.color="red";
                this.validationMessage.message="Nombre es requerido";

                this.showMessage(this.validationMessage);
                return valid;
            } else if(/^\s+$/.test(this.contactoForm.nombre)){
                this.validationMessage.color="red";
                this.validationMessage.message="Nombre no debe ser espacios en blanco";
                this.showMessage(this.validationMessage);

                return valid;
            }

            if(!this.contactoForm.email){

                this.validationMessage.color="red";
                this.validationMessage.message="Correo electrónico es requerido";

                this.showMessage(this.validationMessage);
                return valid;
            } else if(/^\s+$/.test(this.contactoForm.email)){
                this.validationMessage.color="red";
                this.validationMessage.message="Correo electrónico no debe ser espacios en blanco";
                this.showMessage(this.validationMessage);
                
                return valid;
            } else if(!/@[a-zA-Z0-9]+.[a-zA-Z0-9]+/.test(this.contactoForm.email)){
                this.validationMessage.color="red";
                this.validationMessage.message="Correo electrónico debe ser válido";
                this.showMessage(this.validationMessage);
                
                return valid;
            }
            if(!this.contactoForm.selectedMotivo){

                this.validationMessage.color="red";
                this.validationMessage.message="Motivo es requerido";

                this.showMessage(this.validationMessage);
                return valid;
            }

            if(!this.contactoForm.mensaje){

                this.validationMessage.color="red";
                this.validationMessage.message="Mensaje es requerido";

                this.showMessage(this.validationMessage);
                return valid;
            } else if(/^\s+$/.test(this.contactoForm.mensaje)){
                this.validationMessage.color="red";
                this.validationMessage.message="Mensaje no debe ser espacios en blanco";
                this.showMessage(this.validationMessage);
                
                return valid;
            }

            return true;
        }
    }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }

  .v-application .primary{
      background-color: #bd3535 !important;
  }

</style>