<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1>Misión</h1>
                </header>
                <p>
                    Ser una institución, orientada al
                    mejoramiento en la prestación de los
                    servicios de gestión municipal,
                    apoyando los procesos del gobierno
                    local moderno, eficiente, y auto
                    sostenibles, con la capacidad de
                    priorizar y optimizar el uso de los
                    recursos, para la población de todos
                    santos cuchumatán, huehuetenango.
                </p>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
export default {
  components: {  Header, SocialLinks },
    
}
</script>
<style scoped>
</style>