<template>
    <v-app id="obras">
        <viewer :images="getImagesViewer" hidden>
            <img v-for="src in getImagesViewer" :key="src" :src="src">
        </viewer>
        <v-row v-if="!isLoaded" class="pa-0 ma-0" justify="center" align="center">
            <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                <v-progress-linear :color="$store.state.accentColor" indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-container fluid>
            <v-row  class="pa-0 ma-0" justify="center">
                <v-col cols="6" sm="4" md="2" lg="2" xl="2">
                    <v-select
                    :item-color="$store.state.accentColor"
                    :color="$store.state.accentColor"
                    :items="years"
                    v-model="year"
                    label="Año"
                    solo
                    @change="onYearChange"
                    ></v-select>
                </v-col>
                <v-col cols="6" sm="4" md="2" lg="2" xl="2">
                    <v-select
                    :item-color="$store.state.accentColor"
                    :color="$store.state.accentColor"
                    :items="months"
                    v-model="month"
                    label="Mes"
                    solo
                    @change="onMonthChange"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="isLoaded" justify="center">
                <v-dialog v-model="showDeleteDialog" max-width="500" scrollable>
                    <v-card :loading="savingLoader" :disabled="savingLoader">
                        <v-card-title>Confirmación</v-card-title>
                        <v-card-text>
                            ¿Desea eliminar la obra '{{selectedObra.titulo}}'?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="showDeleteDialog=false" text>No</v-btn>
                            <v-btn @click="deleteObra(selectedObra.idPublicacion)" text>Si</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="showObraDialog" max-width="500" scrollable>
                    <v-card>
                        <v-card-title>Detalle de obra</v-card-title>
                        <v-card-text>
                            <v-carousel height="300" hide-delimiter-background>
                                <v-carousel-item v-for="(img,index) in getImages" :key="index">
                                    <v-row no-gutters justify="center"><v-img @click="onseeImages(selectedObra)" :src="getImage(img)" contain></v-img></v-row>
                                </v-carousel-item>
                            </v-carousel>
                            <p class="headline mb-2">{{selectedObra.titulo}}</p>
                            <p>{{selectedObra!=''?convertToReadeableDate(selectedObra.FechaTiempo):""}}</p>
                            <p>{{selectedObra.contenido}}</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="showObraDialog=false" text>Ok</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="newObraDialog" max-width="400" persistent scrollable>
                    <v-card :loading="savingLoader" :disabled="savingLoader">
                        <v-card-title>
                            Nueva obra
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="newObraForm">
                                <br>
                                <v-text-field
                                    label="Título de obra"
                                    :color="$store.state.accentColor"
                                    v-model="obraForm.titulo"
                                    :rules="obraForm.tituloRules"
                                    counter="100"
                                    autocomplete="off"
                                >

                                </v-text-field>
                                <v-list v-if="newObraDialog">
                                    <v-list-item v-for="(img,index) in imgsToUpload" :key="index">
                                        <v-list-item-avatar class="text-h4">{{img.number}}</v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-row no-gutters>
                                                <v-col cols="8" align-self="center" align="center">
                                                    <v-img :src="createURLImage(img)" max-width="200" contain>
                                                        <v-row no-gutters justify="center" v-if="!img.img&&!img.url">
                                                            <div>No imagen</div>
                                                        </v-row>
                                                        <v-row no-gutters justify="end" v-if="img.img||img.url">
                                                            <v-btn icon @click="removeimg(img.number)"><v-icon>clear</v-icon></v-btn>
                                                        </v-row>
                                                    </v-img>
                                                </v-col>
                                                <v-col cols="4" align-self="center">
                                                    <v-row justify="center">
                                                        <v-btn icon large @click="onaddImage(img.number)"><v-icon>file_upload</v-icon></v-btn>
                                                    </v-row>
                                                    <v-row justify="center">
                                                        <v-file-input v-model="img.img" :ref="`fileinput${img.number}`" style="display:none" />
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-textarea :rules="obraForm.contenidoObraRules" 
                                v-model="obraForm.contenidoObra" 
                                label="Contenido de obra" 
                                :color="$store.state.accentColor"
                                autocomplete="off"
                                counter="5000">

                                </v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="newObraDialog=false">Cancelar</v-btn>
                            <v-btn text @click="saveObra()">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="editObraDialog" max-width="400" persistent scrollable>
                    <v-card :loading="savingLoader" :disabled="savingLoader">
                        <v-card-title>
                            Edición de obra
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="editObraForm">
                                <br>
                                <v-text-field
                                    label="Título de obra"
                                    :color="$store.state.accentColor"
                                    v-model="obraForm.titulo"
                                    :rules="obraForm.tituloRules"
                                    counter="100"
                                    autocomplete="off"
                                >

                                </v-text-field>
                                <v-row no-gutters class="text-subtitle-1 font-weight-bold" justify="center">Imagenes existentes</v-row>
                                <v-list v-if="editObraDialog">
                                    <v-list-item v-for="(img,index) in imgsToUpload" :key="index">
                                        <v-list-item-avatar class="text-h4">{{img.number}}</v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-row no-gutters>
                                                <v-col cols="8" align-self="center" align="center">
                                                    <v-img :src="createURLImage(img)" max-width="200" contain>
                                                        <v-row no-gutters justify="center" v-if="!img.img&&!img.url">
                                                            <div>No imagen</div>
                                                        </v-row>
                                                        <v-row no-gutters justify="end" v-if="img.img||img.url">
                                                            <v-btn icon @click="removeimg(img.number)"><v-icon>clear</v-icon></v-btn>
                                                        </v-row>
                                                    </v-img>
                                                </v-col>
                                                <v-col cols="4" align-self="center">
                                                    <v-row justify="center">
                                                        <v-btn icon large @click="onaddImage(img.number)"><v-icon>file_upload</v-icon></v-btn>
                                                    </v-row>
                                                    <v-row justify="center">
                                                        <v-file-input v-model="img.img" :ref="`fileinput${img.number}`" style="display:none" />
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <br>
                                <v-textarea :rules="obraForm.contenidoObraRules" 
                                v-model="obraForm.contenidoObra" 
                                label="Contenido de obra" 
                                :color="$store.state.accentColor"
                                counter="5000"
                                autocomplete="off"
                                >

                                </v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="editObraDialog=false">Cancelar</v-btn>
                            <v-btn text @click="updateObra()">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-col v-for="(obra) in obras" :key="obra.idPublicacion" cols="12" xs="12" sm="12" md="9" lg="6" xl="5">
                    <v-card outlined>
                        <v-btn icon style="position:absolute; right:0px" v-if="$store.state.UserLevel=='1'" @click="showDeleteDialog=true;selectedObra=obra;"><v-icon>clear</v-icon></v-btn>
                        <div v-if="$vuetify.breakpoint.xsOnly">
                            <v-carousel cycle hide-delimiter-background>
                                    <v-carousel-item v-for="(img,index) in obra.imagenes" :key="index">
                                    <v-img @click="onseeImages(obra)" :src="getImage(img)" height="200"></v-img>
                                    </v-carousel-item>
                            </v-carousel>
                            <v-img :src="obra.imagenes.length>0?obra.imagenes[0]:''" height="200"></v-img>
                                <v-card-title>
                                    {{obra.titulo}}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{convertToReadeableDate(obra.FechaTiempo)}}
                                </v-card-subtitle>
                                <v-card-text>
                                    {{shortenedContent(obra.contenido)}}
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text rounded @click="selectedObra=obra;showObraDialog=true;">Ver detalles</v-btn>
                                    <v-btn icon small @click="onedit(obra)" v-if="$store.state.UserLevel=='1'"><v-icon small>edit</v-icon></v-btn>
                                </v-card-actions>
                        </div>
                        <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex flex-no-wrap">
                            <v-avatar
                                size="200"
                                tile
                            >
                                <v-carousel cycle hide-delimiter-background>
                                     <v-carousel-item v-for="(img,index) in obra.imagenes" :key="index">
                                        <v-img @click="onseeImages(obra)" :src="getImage(img)" contain></v-img>
                                     </v-carousel-item>
                                </v-carousel>
                            </v-avatar>
                            <div style="width:100%;">
                                <v-card-title>
                                     {{obra.titulo}}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{convertToReadeableDate(obra.FechaTiempo)}}
                                </v-card-subtitle>
                                <v-card-text class="py-0">
                                    {{shortenedContent(obra.contenido)}}
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn small outlined rounded @click="selectedObra=obra;showObraDialog=true;">Ver detalles</v-btn>
                                    <v-btn icon small @click="onedit(obra)" v-if="$store.state.UserLevel=='1'"><v-icon small>edit</v-icon></v-btn>
                                </v-card-actions>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-btn      
                    :color="$store.state.accentColor"              
                    fixed
                    fab
                    bottom
                    right
                    @click="newObraDialog=!newObraDialog"
                >
                    <v-icon color="white">add</v-icon>
                </v-btn>
                <v-snackbar
                    v-model="snackbarObject.snackbar"
                    :color="snackbarObject.snackbarColor"
                >
                    {{ snackbarObject.snackbarText }}
                </v-snackbar>
                
            </v-row>
        </v-container>
    </v-app>
</template>
<script>

//import BlogItem from '../../CommonComponents/BlogItem'
export default {
    computed:{
        getImagesViewer(){
            if(this.selectedObra)
                if(this.selectedObra.imagenes)
                    if(this.selectedObra.imagenes[0].img)
                        return this.selectedObra.imagenes.map(item=>item.img);
                    else
                        return this.selectedObra.imagenes;

            return "";
        },
        getImages(){
            if(this.selectedObra)
                if(this.selectedObra.imagenes)
                    return this.selectedObra.imagenes;
            return "";
        }
    },
    components:{
        //BlogItem
    },
    data:()=>({
        year:new Date().getFullYear(),
        month:"",
        years:[],
        selectedObra:"",
        months:[
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        isLoaded:false,
        savingLoader:false,
        menu:false,
        newObraDialog:false,
        editObraDialog:false,
        showObraDialog:false,
        showDeleteDialog:false,
        snackbarObject:{
            snackbar:false,
            snackbarText:"",
            snackbarColor:"",
        },
        //imgsToUpload:[],
        imgsToUpload:[
            {
                number:1,
                img:"",
                url:""
            },
            {
                number:2,
                img:"",
                url:""  
            },
            {
                number:3,
                img:"",
                url:""
            },
        ],
        obraForm:{
            titulo:"",
            tituloRules:[
                v => !!v || 'Título es requerido',
                v => (v && v.length <= 100) || 'Título debe ser menor o igual a 50',
            ],
            fecha: "",
            fechaRules:[
                v => !!v || 'Fecha es requerida',
                v => (v && v.length <= 50) || 'La fecha es requerida.',
            ],
            contenidoObra: "",
            contenidoObraRules: [
                v => !!v || 'Contenido de obra es requerido',
                v => (v && v.length <= 5000) || 'El contenido de la obra no debe contener más de 5000 caracteres.',
            ],
            imagenes:[]
        },
        obras:[]
    }),
    methods:{
        onseeImages(obra){
            this.selectedObra=obra;

            this.$viewerApi({
                options:{ "inline": true, "button": true, "navbar": true, "title": true, "toolbar": false, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true},
                images:this.getImagesViewer
            });
        },
        getImage(img){
            if(img.img)
                return img.img;
            return img;
        },
        orderSetImages(){
            if(this.obras)
                this.obras.forEach(obra=>{
                    if(obra.imagenes){
                        if(obra.imagenes[0].img){
                            obra.imagenes.forEach(element=>{
                                this.imgsToUpload[element.order-1].url=element.url;
                            });
                        }else{
                            this.imgsToUpload[0].url=obra.imagenes[0];
                        }
                    }
                });
        },
        clearImgArray(){
            this.imgsToUpload.forEach(element=>{
                element.img="";
                element.url="";
            });
        },
        onaddImage(number){
            var element=Object.entries(this.$refs).find(item=>item[0].includes(`fileinput${number}`));

            if(element)
                element[1][0].$refs.input.click();
        },
        deleteOldImgs(index){
            this.obraForm.imagenes.splice(index,1);
        },
        /*onselectImages(val){
            if(val){
                val.forEach(item=>{
                    if(this.obraForm.imagenes.some(item2=>item2.includes(item.name))){
                        this.imgsToUpload=[];
                    }
                });
            }
        },*/
        removeimg(number){
            var foundedimg=this.imgsToUpload.find(item=>item.number==number);

            if(foundedimg){
                if(foundedimg.img)
                    foundedimg.img="";
                else if(foundedimg.url)
                    foundedimg.url="";
            }

        },
        createURLImage(img){
            if(img.img)
                return URL.createObjectURL(img.img);

            if(img.url)
                return img.url;

            return "";
        },
        getImagenName(imagen){
            if(imagen){
                var splitName=imagen.split("/");
    
                return splitName[splitName.length-1];
            }
        },
        async onedit(obra){
            this.editObraDialog=true;
            await this.loadObra(obra);
        },
        convertToReadeableDate(unparsedDateTime){
            let onlyDate=unparsedDateTime.split(" ");
            let splittedDate=onlyDate[0].split("-");

            return splittedDate[2]+" de "+this.months[parseInt(splittedDate[1])-1]+" "+splittedDate[0];
        },
        shortenedContent(contentString){
            return contentString.substring(0,50)+"...";
        },
        onYearChange(anio){
            this.loadObras(anio,this.months.indexOf(this.month)+1)
        },
        onMonthChange(mes){
            this.loadObras(this.year,this.months.indexOf(mes)+1)
        },
        async loadObras(anio,mes){
            this.isLoaded=false;

            await this.$axios.get(`obras/Obras.php?year=${anio}&month=${mes}`).then(response=>{
                if(response.status==200){
                    if(response.data.code==141){
                        this.obras=response.data.data;
                        this.orderSetImages();
                        console.log(this.obras);
                    }
                }
            }).catch(error=>{
                error;
                this.showSnackbar(`Ha ocurrido un error grave al cargar los documentos, si el error persiste contacte a soporte.`,"red");
            });

            this.isLoaded=true;
        },
        async loadObra(obra){
            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.get(`obras/Obras.php?obra=${obra.idPublicacion}`).then(response=>{
                if(response.status==200){
                    if(response.data.code==141){
                        this.initializeObra(response.data.data[0]);
                    }
                }
            }).catch(error=>{
                error;
                this.showSnackbar(`Ha ocurrido un error grave al cargar los documentos, si el error persiste contacte a soporte.`,"red");
            });

            this.savingLoader=false;
        },
        initializeObra(data){
            this.clearImgArray();

            this.obraForm.idPublicacion=data.idPublicacion;
            this.obraForm.titulo=data.titulo;
            this.obraForm.contenidoObra=data.contenido;
            this.obraForm.imagenes=data.imagenes;

            this.obraForm.imagenes.forEach(element=>{
                if(element.img)
                    this.imgsToUpload[element.order-1].url=element.img;
                else
                    this.imgsToUpload[0].url=this.obraForm.imagenes[0];
            });
        },
        async updateObra(){
            if(this.$refs.editObraForm.validate()){
                let contendForm=new FormData();
                let orderImg=[];

                contendForm.append("titulo",this.obraForm.titulo);
                contendForm.append("contenido",this.obraForm.contenidoObra);

                this.obraForm.imagenes=[];
                this.imgsToUpload.forEach(urlIMG=>{
                    if(urlIMG.url&&!urlIMG.img){
                        this.obraForm.imagenes.push({
                            img:urlIMG.url,
                            order:urlIMG.number
                        });
                    }
                });

                contendForm.append("imagenes",JSON.stringify(this.obraForm.imagenes));

                this.imgsToUpload.forEach(img=>{
                    if(img.img){
                        contendForm.append(img.number,img.img);    
                        orderImg.push(img.number);
                    }
                });

                contendForm.append("imgOrder",JSON.stringify(orderImg));
                this.savingLoader=this.$store.state.accentColor;

                await this.$axios.post(`obras/Update.php?obra=${this.obraForm.idPublicacion}`,contendForm).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 821:{
                                this.showSnackbar(`La obra ha sido actualizada correctamente.`,"green");

                                this.clearImgArray();
                                this.loadObras(this.year,this.months.indexOf(this.month)+1);
                                this.$refs.editObraForm.reset();
                            }break;
                            case 820:{
                                this.showSnackbar(`Ha ocurrido un error al actualizar la obra, si el error persiste contacte a soporte.`,"");
                            }break;
                        }
                    }
                }).catch(error=>{
                    error;
                    this.showSnackbar(`Ha ocurrido un error grave al actualizar la obra, si el error persiste contacte a soporte.`,"red");
                });

                this.savingLoader=false;
                this.editObraDialog=false;
            }
        },
        async saveObra(){
            if(this.$refs.newObraForm.validate()){
                let contendForm=new FormData();
                let arrayImgOrder=[];

                contendForm.append("titulo",this.obraForm.titulo);
                
                this.obraForm.fecha=`${this.year}-${this.months.indexOf(this.month)+1}-${new Date().getDate()}`;
                contendForm.append("fecha",this.obraForm.fecha);
                contendForm.append("contenido",this.obraForm.contenidoObra);
                

                this.imgsToUpload.forEach(img=>{
                    if(img.img){
                        contendForm.append(img.number,img.img);
                        arrayImgOrder.push(img.number);
                    }
                });

                contendForm.append("imgOrder",JSON.stringify(arrayImgOrder));
                this.savingLoader=this.$store.state.accentColor;

                await this.$axios.post("obras/Create.php",contendForm).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 821:{
                                this.showSnackbar(`La obra ha sido agregada correctamente.`,"green");

                                this.clearImgArray();
                                this.loadObras(this.year,this.months.indexOf(this.month)+1);
                                this.$refs.newObraForm.reset();
                            }break;
                            case 820:{
                                this.showSnackbar(`Ha ocurrido un error al guardar la obra, si el error persiste contacte a soporte.`,"");
                            }break;
                        }
                    }
                }).catch(error=>{
                    if(error.response!=null){
                        if(error.response.status==404){
                            if(error.response.data!=null){
                                if(error.response.data.code==29||error.response.data.code==25){
                                    this.$cookies.remove("AUTH_TOKEN");
                                    this.$router.replace({name:"Login"});           
                                }
                            }
                        }   
                    }

                    this.showSnackbar(`Ha ocurrido un error grave al guardar la obra, si el error persiste contacte a soporte.`,"red");
                });

                this.savingLoader=false;
                this.newObraDialog=false;
            }
        },
        async deleteObra(id){
            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.delete(`obras/Delete.php?obra=${id}`).then(response=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 140:
                            this.showSnackbar(`La obra ya ha sido eliminada.`);
                            break;
                        case 141:
                            this.showSnackbar(`La obra ha sido eliminada correctamente.`,"green");
                            this.loadObras(this.year,this.months.indexOf(this.month)+1);
                            break;
                    }
                }
            }).catch(error=>{
                if(error.response!=null){
                        if(error.response.status==404){
                            if(error.response.data!=null){
                                if(error.response.data.code==29||error.response.data.code==25){
                                    this.$cookies.remove("AUTH_TOKEN");
                                    this.$router.replace({name:"Login"});           
                                }
                            }
                        }   
                    }

                this.showSnackbar(`Ha ocurrido un error grave al eliminar la obra, si el error persiste contacte a soporte.`,"red");
            });

            this.savingLoader=false;
            this.showDeleteDialog=false;
        },
        showSnackbar(text,color){
            this.snackbarObject.snackbar=false;
            
            this.snackbarObject.snackbarText=text;
            
            this.snackbarObject.snackbarColor=color;
            this.snackbarObject.snackbar=true;
        }
    },
    created(){
        for(var year=2020;year<=new Date().getFullYear();year++){
            this.years.push(year);
        }

        this.month=this.months[new Date().getMonth()];

        this.loadObras(new Date().getFullYear(),new Date().getMonth()+1);
    }
}
</script>
<style scoped>

</style>