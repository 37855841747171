<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <viewer :images="getImagesViewer" hidden>
          <img v-for="src in getImagesViewer" :key="src" :src="src">
        </viewer>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1>Obras</h1>
                </header>
                <div v-if="!isLoading">
                    <div class="posts">
                        <article v-for="(obra,index) in obrasData" :key="index">
                            <div class="image" >
                                <v-carousel height="250" hide-delimiter-background :show-arrows="false">
                                    <v-carousel-item v-for="(img,index) in obra.imagenes" :key="index">
                                        <v-img @click="onseeImages(obra)" :src="getImagen(img)" contain></v-img>
                                    </v-carousel-item>
                                </v-carousel>
                            </div>
                            <h3 class="no-margin">{{obra.titulo}}</h3>
                            <p class="text-caption">{{getHumanDate(obra.FechaTiempo)}}</p>
                            <p>{{getResumeContent(obra.contenido)}}</p>
                            <ul class="actions">
                                <li><button class="button" @click="onselectObra(obra)">Ver más</button></li>
                            </ul>
                        </article>
                    </div>
                    <v-row justify="center" v-if="lastCounter==3"><v-btn @click="loadMore" text rounded x-small>Cargar más</v-btn></v-row>
                </div>
                <v-row no-gutters justify="center" v-if="isLoading">
                    <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                        <v-progress-linear indeterminate :color="$store.state.accentColor"></v-progress-linear>
                    </v-col>
                </v-row>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
            <v-app>
                <router-view/>
            </v-app>
    </div>
</template>
<script>
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
import GetReadableDate from '../../utils/date'
import Vue from 'vue'

Vue.use(VueViewer);

export default {
  components: {  Header, SocialLinks },
  computed:{
    getImagesViewer(){
        if(this.selectedObra)
            if(this.selectedObra.imagenes)
                if(this.selectedObra.imagenes[0].img)
                    return this.selectedObra.imagenes.map(item=>item.img);
                else
                    return this.selectedObra.imagenes;

        return "";
    }
  },
  data:function(){
      return ({
          isLoading:false,
          obrasData:[],
          lastCounter:0,
          counterPagination:1,
          selectedObra:""
      })
  },
  methods:{
    onseeImages(obra){
        this.selectedObra=obra;

        this.$viewerApi({
            options:{ "inline": true, "button": true, "navbar": true, "title": true, "toolbar": false, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true},
            images:this.getImagesViewer
        });
    },
        getImagen(img){
            if(img)
                if(img.img)
                    return img.img;
            return img;
        }, 
      getHumanDate:GetReadableDate,
      getResumeContent(content){
        if(content)
            if(content.length>130){
                return `${content.substring(0,130)}...`;
            }
        return content;
      },
      onselectObra(obra){
          this.$router.push({name:"Obras detalle",params:{obra:obra.idPublicacion}});
      },
      getDate(date){
          return date.split(" ")[0];
      },
      getObras(){return this.$axios.get(`obras/PublicObras.php?page=${this.counterPagination}`)},
      async loadAll(){
          this.isLoading=this.$store.state.accentColor;

          await this.$axios.all([this.getObras()]).then((response)=>{
              if(response[0].status==200){
                  if(response[0].data.code==141){
                      this.lastCounter=response[0].data.data.length;

                      this.obrasData=this.obrasData.concat(response[0].data.data);
                  }
              }
          }).catch((error)=>{
              console.log(error);
          });

          this.isLoading=false;
      },
        loadMore(){
            this.counterPagination++;
            this.loadAll();
        }
  },
  created(){
      this.loadAll();
  }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
.no-margin{
    margin: 0 !important;
}
</style>