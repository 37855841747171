<template>
        <v-app>
            <v-container fill-height>
                <v-row justify="center">
                    <v-col cols="12" sm="12" md="6" lg="3" align="center">
                        <v-card elevation="5" :loading="isLoading" :disabled="isLoading">
                            <v-card-title>
                                Iniciar sesión
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="login">
                                    <v-row no-gutters>
                                        <input class="v-text-field" type="text" v-model="login.usuario" :rules="login.usuarioRules" placeholder="Usuario" />
                                    </v-row>
                                    <br>
                                    <v-row no-gutters>
                                        <input :type="login.showpassword?'text':'password'" v-model="login.contrasenia" placeholder="Contraseña" />
                                    </v-row>
                                    <br>
                                    <v-row no-gutters justify="center">
                                        <input v-model="login.showpassword" type="checkbox" id="showPassword">
                                        <label for="showPassword">Mostrar contraseña</label>
                                    </v-row>
                                    <br>
                                    <v-row no-gutters>
                                        <v-btn block text @click="doLogin">Iniciar sesión</v-btn>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>        
                        <br>
                        <v-btn rounded text x-small link href="/">
                            Regresar a página principal
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-snackbar
                v-model="snackbarObject.snackbar"
                :color="snackbarObject.snackbarColor"
            >
                {{ snackbarObject.snackbarText }}
            </v-snackbar>
        </v-app>
</template>
<script>

export default {
    data:function(){
        return ({
            testDialog:false,
            isLoading:false,
            login:{
                usuario:"",
                usuarioRules:[
                    v=>!!v||"Usuario es obligatorio."
                ],
                showpassword:false,
                contrasenia:"",
                contraseniaRules:[
                    v=>!!v||"Contraseña es obligatorio."
                ]
            },
            snackbarObject:{
                snackbar:false,
                snackbarText:"",
                snackbarColor:"",
            },
        })
    },
    methods:{
        async doLogin(){
            this.isLoading=this.$store.state.accentColor;

            await this.$axios.post(
                "login/AuthenticationWeb.php",this.login
            ).then(response=>{
                switch(response.status){
                    case 200:{
                        this.$cookies.set(response.data.data.tokenType,response.data.data.token);
                        this.$router.replace({name:"Gestión - Inicio"});
                    }break;
                }

                return;
            }).catch(error=>{
                if(error.response!=null){
                    if(error.response.status==400){
                        this.showSnackbar("Usuario o contraseña incorrecto.","red",0);
                    }
                }

                this.showSnackbar("Ha ocurrido un error, comuníquese con sopórte técnico.","red",1000);
            });

            this.isLoading=false;
        },
        showSnackbar(text,color,indexAction){
            this.snackbarObject.snackbar=false;
            
            switch(indexAction){
                case 0:{
                    this.snackbarObject.snackbarText=text;
                }break;
                case 1:{
                    this.snackbarObject.snackbarText=text;
                }break;
                default:{
                    this.snackbarObject.snackbarText=text;
                }break;
            }
            
            this.snackbarObject.snackbarColor=color;
            this.snackbarObject.snackbar=true;
        }
    },
    created(){
    }
}
</script>
<style scoped>
.input {
  all: initial !important;
}
</style>