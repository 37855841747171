<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
.justify-center{
  justify-content: center;
}
.wrap-text {
  white-space: unset !important;
}
</style>
