<template>
    <header id="header">
        <router-link to="/"  class="logo"><strong>Municipalidad de Todos Santos Cuchumatán, Huehuetenango</strong></router-link>
        <img src="../assets/images/isotipo.jpg" class="isotipo" alt="">
    </header>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>