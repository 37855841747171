<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1>Visión</h1>
                </header>
                <p>
                    Facilitar y fortalecer los procesos de
                    los servicios municipales a través de
                    una gestión transparente, eficiente,
                    eficaz y competitiva, promoviendo
                    acciones que permitan a las
                    autoridades locales, fortalecer y
                    mejorar con el desarrollo sostenible
                    de nuestro municipio de todos santos
                    cuchumatán, huehuetenango.
                </p>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
export default {
  components: {  Header, SocialLinks },
    
}
</script>
<style scoped>

</style>