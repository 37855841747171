<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1 style="display:inline" v-for="(path,index) in $router.currentRoute.matched" :key="index"><div style="display:inline" v-if="index>1">&nbsp;/&nbsp;</div><a  :href="verifyLastPath(path.path)">{{getPathTitle(path.name,index)}}</a></h1>
                </header>
                <br>
                <br>
                <div v-if="$router.currentRoute.name=='SINACIG'">
                    <v-app id="sinacig">
                        <v-row class="pa-0" justify="center">
                            <v-col cols="12" sm="12" md="4" xl="2">
                                <v-card rounded="xl" hover ripple :to="{name:'SINACIG-YEAR',params:{year:2021}}">
                                    <v-sheet color="transparent">
                                        <v-card-title class="text-md-h5 font-weight-bold justify-center">
                                            2021
                                        </v-card-title>
                                    </v-sheet>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" xl="2">
                                <v-card rounded="xl" hover ripple :to="{name:'SINACIG-YEAR',params:{year:2022}}">
                                    <v-sheet color="transparent">
                                        <v-card-title class="text-md-h5 font-weight-bold justify-center">
                                            2022
                                        </v-card-title>
                                    </v-sheet>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-app>
                </div>
                <router-view />
            </section>
            <br>
            <br>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
export default {
  components: {  Header, SocialLinks },
  computed:{

  },
  data:function(){
      return ({
      })
  },
  methods:{
    getPathTitle(path){
        console.log(this.$router.currentRoute.matched);

        if(path=="SINACIG-YEAR")    
            return`${this.$route.params.year}`;
        
        return path;
    },
    verifyLastPath(path){
        var splitPath=path.split("/");

        splitPath.forEach((element,index) => {
            if(element.includes(":")){
                switch(element){
                    case ":year":
                        splitPath[index]=this.$route.params.year;
                        break;
                }
            }
        });
        return splitPath.join("/");
    }
  }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
</style>