<template>
    <div>
        <v-app>
            <v-row no-gutters justify="center" v-if="!isLoaded">
                <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                    <v-progress-linear indeterminate :color="$store.state.accentColor"></v-progress-linear>
                </v-col>
            </v-row>
            <section v-if="isLoaded" >
                <v-row no-gutters justify="center"><h3>Incisos</h3></v-row>
                <v-row class="pa-0 ma-0" align="start">
                    <v-col v-for="(categoria) in sections" :key="categoria.idCategoria" cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                        <v-card class="rounded-xl" elevation="5">
                            <div class="pa-4 pb-0"><h5>{{categoria.categoria}}</h5></div>
                            <v-card-text class="pt-0">
                                <v-list class="pa-0" shaped>
                                    <v-list-item dense v-for="(pdf) in categoria.pdfs" :key="pdf.idCategoriasPdf" class="text-caption pa-0" @click="()=>{}">
                                        {{pdf.descripcion=="undefined"?"***":pdf.descripcion}}
                                        <v-spacer />
                                        <v-btn icon link :href="pdf.urlPdf" target="_blank"><v-icon>download</v-icon></v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <br>
            </section>
        </v-app>
    </div>
</template>
<script>
export default {
    data:()=>({
        isLoaded:false,
        sections:[],
        months:[
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ]
    }),
    methods:{
        getMonthName(month){
            return this.months[month.meses-1];
        },
            async loadDocuments(){
            this.isLoaded=false;

            await this.$axios.get(`categorias/PublicCategorias.php?anio=${this.$route.params.year}&month=${this.$route.params.month}`).then(response=>{
                if(response.status==200){
                    if(response.data.code==141){
                        this.sections=response.data.data;
                    }
                }
            });

            this.isLoaded=true;
        }
    },
    created(){
        this.loadDocuments();
    }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
</style>