<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1>Municipio</h1>
                </header>
                <header class="major">
                    <h2>Historia</h2>
                </header>
                <h3>Todos Santos Cuchumatán, Huehuetenango</h3>
                <p>Durante el período colonial Todos Santos Cuchumatán perteneció a
                Huehuetenango. Este a su vez pertenecía al departamento de Totonicapán. <br>
                Ya en 1866, Huehuetenango fue elevado a la categoría de departamento.
                Todos Santos Cuchumatán, Huehuetenango, representan uno de los acontecimientos 
                más importantes del departamento, en el cual la adrenalina se mezcla con el folclor y
                la tradición se observa en la tradicional carrera de caballos que se celebra todos
                los años el 1 de noviembre.</p>
                <p>El municipio de Todos Santos Cuchumatán está localizado en la región
                noroccidente del territorio de Guatemala, en el departamento
                de Huehuetenango. Se encuentra a 316 kilómetros de la ciudad capital
                de Guatemala.</p>
                <br>
                <h4>Descripción</h4>
                <p>Cuenta con una extensión territorial de 300 kilómetros cuadrados. Dicho
                territorio pertenece a las tierras altas sedimentarias, donde se encuentra
                la Cordillera de los Cuchumatanes. Por esta razón, el clima se caracteriza por
                ser frío la mayor parte del año.</p>
                <p>Al norte colinda con Concepción Huista y San Juan Ixcoy. Al oriente en cambio,
                con Chiantla. Además, al occidente limita con Concepción Huista y Santiago
                Chimaltenango. Finalmente, al sur con San Sebastián, Huehuetenango, San
                Juan Atitlán y Santiago, Chimaltenango.</p>
                <br>
                <h4>Origen del nombre</h4>
                <p>La palabra Cuchumatán proviene del idioma Mam y
                significa Congregación o Reunión por la fuerza. Según su connotación cultural,
                da la idea que las montañas fueron reunidas por la fuerza.</p>
                <br>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import Header from '../components/Header.vue'
import SocialLinks from '../components/SocialLinks.vue'
export default {
  components: {  Header, SocialLinks },
    
}
</script>
<style scoped>

</style>