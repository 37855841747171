import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
      accentColor:"#bd3535",
      subdirectoryapi:"muni_tsch/api/rest/v1.0/",
      AUTH_TOKEN:"",
      UserLevel:""
    },
    mutations: {
        setAuthToken: (state,val) =>state.AUTH_TOKEN = val,
        setUserLevel:(state,val)=> state.UserLevel=val
    },
    getters:{

    }
  });

  export default store;