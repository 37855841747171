<template>
    <v-app>
        <v-app-bar
        flat
        app
        >   
            <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>
               {{$router.currentRoute.name}}
            </v-toolbar-title>
            <v-spacer />
        </v-app-bar>
        <v-navigation-drawer
        app
        v-model="drawer"
        temporary
        >
            <v-list>
                <v-row class="pa-0 ma-0" justify="center">
                    <v-btn small light color="white" @click="returnHome">Regresar a página principal</v-btn>
                </v-row>
                <br>
                <v-subheader>Menu</v-subheader>
                <v-list-item :to="drawerItems[0].pathTo">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{drawerItems[0].showText}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="drawerItems[1].pathTo" v-if="userLevel==drawerItems[1].levelSection||userLevel==1||userLevel==4">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{drawerItems[1].showText}}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group :color="$store.state.accentColor" v-if="userLevel==drawerItems[2].levelSection||userLevel==1||userLevel==4">
                    <template v-slot:activator>
                        <v-list-item-title>Publicaciones</v-list-item-title>
                    </template>
                    <v-list-item link :to="{name:'Gestión - Noticias'}">
                        <v-list-item-icon>
                            
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Noticias
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link :to="{name:'Gestión - Avisos'}">
                        <v-list-item-icon>
                            
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Avisos
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item link :to="{name:'Gestión - Obras'}">
                        <v-list-item-icon>
                            
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Obras
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <br>
                <v-list-item-group>
                    <v-subheader>Cuenta</v-subheader>
                    <v-list-item @click="sessionDialog=!sessionDialog;drawer=false;">
                        <v-list-item-content>
                            <v-list-item-title>
                                Cerrar Sesión
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-dialog
        v-model="sessionDialog"
        max-width="400"
        >
            <v-card>
                <v-card-title class="headline">Confirmación</v-card-title>

                <v-card-text>
                ¿Desea cerrar sesión?
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    text
                    @click="sessionDialog = false"
                >
                    No
                </v-btn>

                <v-btn
                    text
                    @click="log_out()"
                >
                    Si
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-main>
            <v-container fluid>
                <transition name="scroll-y-transition" mode="out-in">
                    <router-view />
                </transition>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
export default {
    data:()=>({
        drawer:false,
        sessionDialog:false,
        drawerItems:[
            {
                showText:"Inicio",
                pathTo:"/admin/inicio",
                levelSection:0
            },
            {
                showText:"Decretos",
                pathTo:"/admin/decretos",
                levelSection:2
            },
            {
                showText:"Publicaciones",
                pathTo:"/admin/publicaciones",
                levelSection:3
            }
        ],
        userLevel:0
    }),
    methods:{
        returnHome(){
            window.location.href="/";
        },
        log_out(){
            this.$cookies.remove("AUTH_TOKEN");
            this.$router.replace({name:"Login"});
        },
        decodeJWT(cname){
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
        },
    },
    created(){
        let claims=JSON.parse(atob(this.decodeJWT("AUTH_TOKEN").split('.')[1]));
        this.userLevel=claims["rolLevel"];

        this.$store.commit("setAuthToken",this.$cookies.get("AUTH_TOKEN"));
        this.$store.commit("setUserLevel",claims["rolLevel"]);
    }
}
</script>
<style scoped>

</style>