const Months=[
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
];

function GetRedeableDate (date){
    if(date){
        let onlyDate=date.split(" ");
        let splittedDate=onlyDate[0].split("-");
    
        return splittedDate[2]+" de "+Months[parseInt(splittedDate[1])-1]+" "+splittedDate[0];
    }
    return "";
}

export {Months};
export default GetRedeableDate;