import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PublicLayout from './layouts/PublicLayout'
  import Inicio from './pages/Inicio'
  import Avisos from './pages/novedades/Avisos'
    import AvisosDetalle from './pages/novedades/AvisosDetalle'
  import Obras from './pages/novedades/Obras'
  import ObrasDetalle from './pages/novedades/ObrasDetalle'
  import Noticias from './pages/novedades/Noticias'
    import NoticiasDetalle from './pages/novedades/NoticiasDetalle'
  import Autoridades from './pages/municipalidad/Autoridades'
  import Mision from './pages/municipalidad/Mision'
  import Vision from './pages/municipalidad/Vision'
  import Municipio from './pages/Municipio'
  import InformacionPublica from './pages/informacion-publica/InformacionPublica'
    import Inciso from './pages/informacion-publica/Inciso'
    import Anio from './pages/informacion-publica/Anio'
      import Mes from './pages/informacion-publica/Mes'
  import Sinacig from './pages/sinacig/Sinacig'
    import SinacigYear from './pages/sinacig/SinacigYear'
      import Acuerdos from './pages/sinacig/Acuerdos'
      import Capacitacion from './pages/sinacig/Capacitacion'
      import Documentos from './pages/sinacig/Documentos'
      import Informes from './pages/sinacig/Informes'
      import Varios from './pages/sinacig/Varios'
  import BoletoOrnato from './pages/servicios/BoletoOrnato'
  import IUSI from './pages/servicios/IUSI'
  import DenunciasCiudadanas from './pages/DenunciasCiudadanas'
  import Comunicate from './pages/Comunicate'

import PrivateLayout from './layouts/PrivateLayout'
  import Login from './pages/Login'
    import PanelAdmin from './pages/admin/PanelAdmin'
      import InicioAdmin from './pages/admin/Inicio'
      import DecretosAdmin from './pages/admin/Decretos'
      import NoticiasAdmin from './pages/admin/Noticias'
      import AvisosAdmin from './pages/admin/Avisos'
      import ObrasAdmin from './pages/admin/Obras'

import vuetify from './plugins/vuetify'
import store from './store/store'
import VueCookies from 'vue-cookies';

axios.defaults.withCredentials=true;
axios.defaults.baseURL=window.location.hostname.includes("localhost")?`http://localhost/${store.state.subdirectoryapi}`:`https://todossantoscuchumatan.gob.gt/${store.state.subdirectoryapi}`;
axios.defaults.headers.common['Accept']="text/plain, application/json";

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueCookies)

const routes=new VueRouter({
  base:"/",
  mode:"history",
  routes:[
    {path:"/",component:PublicLayout,children:[
      {path:"",component:Inicio,name:"Inicio"},
        {path:"avisos",component:Avisos,name:"Avisos",children:[
          {path:":aviso/detalle",component:AvisosDetalle,name:"Avisos detalle"}
        ]},
        {path:"obras",component:Obras,name:"Obras",children:[
          {path:":obra/detalle",component:ObrasDetalle,name:"Obras detalle"}
        ]},
        {path:"noticias",component:Noticias,name:"Noticias",children:[
          {path:":noticia/detalle",component:NoticiasDetalle,name:"Noticias detalle"}
        ]},
        {path:"autoridades",component:Autoridades,name:"Autoridades"},
        {path:"mision",component:Mision,name:"Mision"},
        {path:"vision",component:Vision,name:"Vision"},
      {path:"municipio",component:Municipio,name:"Municipio"},  
      {path:"informacion-publica",component:InformacionPublica,name:"Informacion Publica",children:[
        {
          path:"decreto/:decreto/inciso/:inciso",component:Inciso,name:"Inciso",children:[
            {path:":year",component:Anio,name:"Info Publica Año"}
          ]
        },
        {path:":year",component:Anio,name:"Info Publica Año",children:[
          {path:":month",component:Mes,name:"Info Publica Mes"}
        ]}
      ]},
      {path:"sinacig",component:Sinacig,name:"SINACIG",children:[
        {path:":year",component:SinacigYear,name:"SINACIG-YEAR",children:[
          {path:"acuerdos",component:Acuerdos,name:"Acuerdos"},
          {path:"capacitacion",component:Capacitacion,name:"Capacitacion"},
          {path:"documentos",component:Documentos,name:"Documentos"},
          {path:"informes",component:Informes,name:"Informes"},
          {path:"codigo-reglamento-politicas",component:Varios,name:"Codigo de ética, Reglamento, Politicas anticorrupción"}
        ]}
      ]},
      {path:"boleto-ornato",component:BoletoOrnato,name:"Boleto Ornato"},
      {path:"iusi",component:IUSI,name:"IUSI"},
      {path:"denuncias-ciudadanas",component:DenunciasCiudadanas,name:"Denuncias Ciudadanas"},
      {path:"comunicate",component:Comunicate,name:"Comunicate"},
    ]},
    {path:"/login",component:PrivateLayout,children:[
      {path:"",component:Login,name:"Login"},
    ]},
    {path:"/admin",component:PrivateLayout,children:[
      {path:"",component:PanelAdmin,redirect:{name:"Gestión - Inicio"},children:[
        {path:"inicio",component:InicioAdmin,name:"Gestión - Inicio"},
        {path:"decretos",component:DecretosAdmin,name:"Gestión - Decretos"},
        {path:"publicaciones/noticias",component:NoticiasAdmin,name:"Gestión - Noticias"},
        {path:"publicaciones/avisos",component:AvisosAdmin,name:"Gestión - Avisos"},
        {path:"publicaciones/obras",component:ObrasAdmin,name:"Gestión - Obras"},
        {path: '*',redirect:{name:"Gestión - Inicio"}}
      ]},
    ]}
  ]
});

routes.beforeEach(async(to,from,next)=>{
  console.log(to);
  
  if(to.name.includes("Gestión")){
    var result1=await verifyJWT();

    if(result1==1){
      next();
    }else{
      next({name:"Login"});
    }
  }else{
    if(to.name=="Login"){
      var result2=await verifyJWT();

      if(result2==1){
        next({name:"Gestión - Inicio"});
      }else{
        next();
      }
    }else{
      next();
    }
  }
});

async function verifyJWT(){
  return await axios.post("login/VerifyJWT.php",{}
  ).then(response=>{
    if(response.status==200){
      if(response.data.code==13){
        return 1;
      }
    }
  }).catch(()=>{
    return 2;  
  });
}

axios.interceptors.request.use((request)=>{
  if(request.baseURL.includes("api/rest")){
    request.headers.common["Authorization"]=`Authorization ${VueCookies.get("AUTH_TOKEN")}`;
  }
  return request;
},(error)=>{
  return Promise.reject(error);
});

axios.interceptors.response.use((response)=>{
  return response;
},(error)=>{
  if(error.response.status==400){
    if(error.response.data.code==27||error.response.data.code==29||error.response.data.code==25){
      VueCookies.remove("AUTH_TOKEN");
      routes.replace({name:"Login"});
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
});

new Vue({
  store:store,
  router:routes,
  vuetify,
  beforeCreate(){
    Vue.prototype.$axios=axios;
    Vue.prototype.$cookies=Vue.$cookies;
  },
  render: h => h(App)
}).$mount('#app')

