<template>
    <v-dialog persistent v-model="opened" @click:outside="hola()" fullscreen hide-overlay transition="scale-transition">
            <v-card>
                <v-app>
                    <v-app-bar flat app color="transparent">
                        <v-btn icon @click="closeActivity()" x-large>
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-progress-linear v-if="isLoading" indeterminate bottom absolute :color="$store.state.accentColor"></v-progress-linear>
                    </v-app-bar>
                    <v-main class="fill-height" v-if="!isLoading">
                        <v-container class="fill-height">
                            <div class="mb-10">
                                <v-row class="ma-0">
                                    <div :class="'pa-0 text-h5 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h3'">
                                        {{noticiaData.titulo}}
                                    </div>
                                </v-row>
                                <v-row class="ma-0">
                                    <div :class="'text-caption pa-0'">
                                        {{getReadeableDate(noticiaData.FechaTiempo)}}
                                    </div>
                                </v-row>
                            </div>
                            <v-container class="ma-0 pa-0 mb-10" v-if="$vuetify.breakpoint.smAndDown">
                                <v-row v-if="!hasImages" class="fill-height" justify="center" align="center">
                                    <div class="body pa-0">No hay imágenes</div>
                                </v-row>
                                <v-row justify="center">
                                    <v-img v-if="hasImages" :max-width="$vuetify.breakpoint.xsOnly?300:400" :src="firstImage" :height="$vuetify.breakpoint.smAndUp?450:300" contain>
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height" justify="center" align="center">
                                                <v-progress-circular indeterminate :color="$vuetify.theme.dark?'white':'black'"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-row>
                                <br>
                                <br>
                                <v-row class="ma-0">
                                    <div class="text-body pa-0">
                                        {{noticiaData.contenido}}
                                    </div>
                                </v-row>
                            </v-container>
                            <v-row v-if="$vuetify.breakpoint.mdAndUp">
                                <v-col cols="6" align-self="center">
                                    <v-row class="ma-0">
                                        <div class="text-body pa-0">
                                            {{noticiaData.contenido}}
                                        </div>
                                    </v-row>
                                </v-col>
                                <v-col cols="6">
                                    <v-row v-if="!hasImages" class="fill-height" justify="center" align="center">
                                        <div class="body pa-0">No hay imágenes</div>
                                    </v-row>
                                    <v-img v-if="hasImages" :src="firstImage" height="500" contain>
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" justify="center" align="center">
                                                <v-progress-circular indeterminate :color="$vuetify.theme.dark?'white':'black'"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-main>
                </v-app>
            </v-card>
    </v-dialog>    
</template>
<script>
//import CustomSectionHeader from '../CommonComponents/CustomSectionHeader'
import GetReadableDate from '../../utils/date'

export default {
    components:{
        //CustomSectionHeader
    },
    computed:{
        hasImages(){
            if(this.noticiaData)
                if(this.noticiaData.imagenes.length==0)
                    return false;
            return true;
        },
        firstImage(){
            if(this.noticiaData)
                if(this.noticiaData.imagenes.length>0)
                    return this.noticiaData.imagenes[0];
            return "";
        }
    },
    data:()=>({
        opened:false,
        noticiaData:"",
        isLoading:false
    }),
    methods:{
        closeActivity(){
            this.opened=false;
            this.$emit('close');
            this.$router.go(-1);
        },
        hola(){
            this.opened=false;
            this.$emit('close');
            this.$router.go(-1);
        },
        getReadeableDate: GetReadableDate,
        async loadNoticia(){
            this.isLoading=true;
          await this.$axios.get(`noticias/PublicNoticias.php?noticia=${this.$route.params.noticia}`).then((response)=>{
              if(response.status==200){
                  if(response.data.code==141){
                      this.noticiaData=response.data.data[0];
                  }
              }
          }).catch((error)=>{
              console.log(error);
          });

          this.isLoading=false;
      },
    },
    mounted(){
        this.opened=true;
        this.loadNoticia();
    }
}
</script>
<style scoped>
</style>