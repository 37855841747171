<template>
    <div>
        <div v-if="$router.currentRoute.name.includes('Año')">
            <v-app>
                <v-row no-gutters justify="center">
                    <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                        <v-progress-linear indeterminate :color="$store.state.accentColor" v-if="!isLoaded"></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row v-if="isLoaded">
                    <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(month,index) in realMonthsData" :key="index">
                        <v-card ripple elevation="5" class="rounded-xl" @click="toMonth(month)">
                            <v-card-title>    
                                <v-row no-gutters><h4>{{getMonthName(month)}}</h4></v-row>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-app>
        </div>
        <router-view/>
    </div>
</template>
<script>
export default {
    data:()=>({
        isLoaded:false,
        realMonthsData:[],
        months:[
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
    }),
    methods:{
        toMonth(month){
            
            if(this.$router.currentRoute.name=="Decretos Año"){
                this.$router.push({name:"Decretos Mes",params:{month:month.meses}});
            }else{
                this.$router.push({name:"Info Publica Mes",params:{month:month.meses}});
            }
        },
        getMonthName(month){
            return this.months[month.meses-1];
        },
        async loadMonths(){
            this.isLoaded=false;

            var url="";

            if(this.$router.currentRoute.name=="Decretos Año"){
                url="decretoscategorias/PublicDecretosCategorias.php";
            }else{
                url="categorias/PublicCategorias.php";
            }
            
            await this.$axios.get(`${url}?anio=${this.$route.params.year}`).then(response=>{
                if(response.status==200){
                    if(response.data.code==141){
                        this.realMonthsData=response.data.data;
                    }
                }
            });

            this.isLoaded=true;
        }
    },
    created(){
        this.loadMonths();
    }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
</style>