<template>
    <div>
        <div v-if="$router.currentRoute.name.includes('Inciso')">
            <v-app>
                <v-row no-gutters justify="center">
                    <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                        <v-progress-linear indeterminate :color="$store.state.accentColor" v-if="!isLoaded"></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row>
                    <h2>{{getDecretoTitle}}</h2>
                </v-row>
                <v-row>
                    <h3>{{getIncisoTitle}}</h3>
                </v-row>
                <br>
                <br>
                <br>
                <v-row v-if="isLoaded" no-gutters>
                    <v-col>
                        <v-list>
                            <v-list-item v-for="year in documentYears" :key="year.year" class="pb-10">
                                <v-list-item-content>
                                    <div>
                                        <v-row justify="center">
                                            <h2>{{year.year}}</h2>
                                        </v-row>
                                        <v-row no-gutters v-if="inciso.anual==null">
                                            <v-col>
                                                <v-list>
                                                    <v-list-item v-for="month in year.meses" :key="month.mes">
                                                        <v-list-item-content class="px-2">
                                                            <v-expansion-panels>
                                                                <v-expansion-panel>
                                                                    <v-expansion-panel-header>
                                                                        {{months[month.mes-1]}}
                                                                    </v-expansion-panel-header>
                                                                    <v-expansion-panel-content>
                                                                        <v-list dense v-if="month.documentos">
                                                                            <v-list-item justify="center" v-for="file in month.documentos" :key="file.idCategoriasPdf">
                                                                                <a :href="file.urlPdf" target="_blank">{{getFileName(file.urlPdf)}}</a>
                                                                            </v-list-item>
                                                                        </v-list>
                                                                        <v-row v-if="!month.documentos" no-gutters class="text-caption grey--text">
                                                                            <br>
                                                                            No hay documentos
                                                                        </v-row>
                                                                    </v-expansion-panel-content>
                                                                </v-expansion-panel>
                                                            </v-expansion-panels>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="inciso.anual==1">
                                            <v-col>
                                                <v-list>
                                                    <v-list-item v-for="(document,index) in year.documents" :key="index">
                                                        <v-list-item-content class="px-2">
                                                            <v-row justify="center">
                                                                <a :href="document.urlPdf" target="_blank">{{getFileName(document.urlPdf)}}</a>
                                                            </v-row>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-app>
        </div>
        <router-view/>
    </div>
</template>
<script>
export default {
    computed:{
        getIncisoTitle(){
            if(!this.inciso)
                return "Cargando...";
            return this.inciso.categoria;
        },
        getDecretoTitle(){
            if(!this.decreto)
                return "Cargando...";
            return this.decreto.decreto;
        }
    },
    data:()=>({
        isLoaded:false,
        inciso:"",
        decreto:"",
        documentYears:[],
        months:[
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ]
    }),
    methods:{
        getFileName(url){
            var urlPieces=url.split("/");

            return urlPieces[urlPieces.length-1];
        },
        getInciso(){return this.$axios.get(`categorias/CategoriasPublicas.php?categoria=${this.$route.params.inciso}`)},
        getDecreto(){return this.$axios.get(`decretos/DecretosPublicos.php?decreto=${this.$route.params.decreto}`)},
        getYearsMonthsDocs(){return this.$axios.get(`decretos/PublicDecretos.php?decreto=${this.$route.params.decreto}&inciso=${this.$route.params.inciso}`)},
        async loadAll(){
            await this.$axios.all([this.getInciso(),this.getDecreto(),this.getYearsMonthsDocs()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200)
                    if(response[0].data.code==141&&response[1].data.code==141&&response[2].data.code==141){
                        this.inciso=response[0].data.data;
                        this.decreto=response[1].data.data;
                        this.documentYears=response[2].data.data;
                    }
            }).catch((error)=>{
                console.log(error);
            });

            this.isLoaded=true;
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
</style>