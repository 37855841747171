<template>
    <v-dialog persistent v-model="opened" fullscreen hide-overlay transition="scale-transition">
            <v-card>
                <v-app>
                    <viewer :images="getImages" hidden>
                        <img v-for="src in getImages" :key="src" :src="src">
                    </viewer>
                    <v-app-bar flat app color="transparent">
                        <v-btn icon @click="closeActivity()" x-large>
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-progress-linear v-if="isLoading" indeterminate bottom absolute :color="$store.state.accentColor"></v-progress-linear>
                    </v-app-bar>
                    <v-main class="fill-height" v-if="!isLoading">
                        <v-container class="fill-height">
                            <div class="mb-10">
                                <v-row class="ma-0">
                                    <div :class="'pa-0 text-h5 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h3'">
                                        {{obraData.titulo}}
                                    </div>
                                </v-row>
                                <v-row class="ma-0">
                                    <div :class="'text-caption pa-0'">
                                        {{getReadeableDate(obraData.FechaTiempo)}}
                                    </div>
                                </v-row>
                            </div>
                            <v-container class="ma-0 pa-0 mb-10" v-if="$vuetify.breakpoint.smAndDown">
                                <v-row v-if="!hasImages" class="fill-height" justify="center" align="center">
                                    <div class="body pa-0">No hay imágenes</div>
                                </v-row>
                                <v-row justify="center">
                                    <v-carousel v-if="hasImages" hide-delimiter-background>
                                        <v-carousel-item v-for="(img,index) in getImages" :key="index">
                                            <v-row no-gutters justify="center">
                                                <v-img @click="onseeImages()"  :src="img" :max-width="$vuetify.breakpoint.xsOnly?300:400" :height="$vuetify.breakpoint.smAndUp?450:300" contain class="justify-center align-center"></v-img>
                                            </v-row>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-row>
                                <br>
                                <br>
                                <v-row class="ma-0">
                                    <div class="text-body pa-0">
                                        {{obraData.contenido}}
                                    </div>
                                </v-row>
                            </v-container>
                            <v-row v-if="$vuetify.breakpoint.mdAndUp">
                                <v-col cols="6" align-self="center">
                                    <v-row class="ma-0">
                                        <div class="text-body pa-0">
                                            {{obraData.contenido}}
                                        </div>
                                    </v-row>
                                </v-col>
                                <v-col cols="6">
                                    <v-row v-if="!hasImages" class="fill-height" justify="center" align="center">
                                        <div class="body pa-0">No hay imágenes</div>
                                    </v-row>
                                    <v-carousel v-if="hasImages" hide-delimiter-background >
                                        <v-carousel-item v-for="(img,index) in getImages" :key="index">
                                            <v-row no-gutters justify="center">
                                                <v-img @click="onseeImages()" :src="img" height="500" contain class="justify-center align-center"></v-img>
                                            </v-row>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-main>
                </v-app>
            </v-card>
    </v-dialog>    
</template>
<script>
//import CustomSectionHeader from '../CommonComponents/CustomSectionHeader'
import GetReadableDate from '../../utils/date'

export default {
    components:{
        //CustomSectionHeader
    },
    computed:{
        hasImages(){
            if(this.obraData)
                if(this.obraData.imagenes.length==0)
                    return false;
            return true;
        },
        firstImage(){
            if(this.obraData)
                if(this.obraData.imagenes.length>0)
                    return this.obraData.imagenes[0];
            return "";
        },
        getImages(){
            if(this.obraData)
                if(this.obraData.imagenes)
                    if(this.obraData.imagenes[0].img)
                        return this.obraData.imagenes.map(item=>item.img);
                    else
                        return this.obraData.imagenes;
            return "";
        }
    },
    data:()=>({
        opened:false,
        obraData:"",
        isLoading:false
    }),
    methods:{
        onseeImages(){
            this.$viewerApi({
                options:{ "inline": true, "button": true, "navbar": true, "title": true, "toolbar": false, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true},
                images:this.getImages
            });
        },
        closeActivity(){
            this.opened=false;
            this.$emit('close');
            this.$router.go(-1);
        },
        getReadeableDate: GetReadableDate,
        async loadObra(){
            this.isLoading=true;
          await this.$axios.get(`obras/PublicObras.php?obra=${this.$route.params.obra}`).then((response)=>{
              if(response.status==200){
                  if(response.data.code==141){
                      this.obraData=response.data.data[0];
                  }
              }
          }).catch((error)=>{
              console.log(error);
          });

          this.isLoading=false;
      },
    },
    mounted(){
        this.opened=true;
        this.loadObra();
    }
}
</script>
<style scoped>
</style>