<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1>Avisos</h1>
                </header>
                <div v-if="!isLoading">
                    <div class="posts">
                        <article v-for="(aviso,index) in avisosData" :key="index">
                            <div class="image"><img :src="getImagen(aviso)" alt="" /></div>
                            <h3 class="no-margin">{{aviso.titulo}}</h3>
                            <p class="text-caption">{{getHumanDate(aviso.FechaTiempo)}}</p>
                            <p>{{getResumeContent(aviso.contenido)}}</p>
                            <ul class="actions">
                                <li><button class="button" @click="onselectAviso(aviso)">Ver más</button></li>
                            </ul>
                        </article>
                    </div>
                    <v-row justify="center" v-if="lastCounter==3"><v-btn @click="loadMore" text rounded x-small>Cargar más</v-btn></v-row>
                </div>
                <v-row no-gutters justify="center" v-if="isLoading">
                    <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                        <v-progress-linear indeterminate :color="$store.state.accentColor"></v-progress-linear>
                    </v-col>
                </v-row>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
            <v-app>
                <router-view/>
            </v-app>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
import GetReadableDate from '../../utils/date'
export default {
  components: {  Header, SocialLinks },
  data:function(){
      return ({
          isLoading:false,
          avisosData:[],
          lastCounter:0,
          counterPagination:1
      })
  },
  methods:{
        getImagen(publicacion){
            if(publicacion)
                if(publicacion.imagenes.length>0)
                    return publicacion.imagenes[0];
            return "";
        }, 
      getHumanDate:GetReadableDate,
      getResumeContent(content){
          if(content.length>130){
              return `${content.substring(0,130)}...`;
          }
          return content;
      },
      onselectAviso(aviso){
          this.$router.push({name:"Avisos detalle",params:{aviso:aviso.idPublicacion}});
      },
      getDate(date){
          return date.split(" ")[0];
      },
      getAvisos(){return this.$axios.get(`avisos/PublicAvisos.php?page=${this.counterPagination}`)},
      async loadAll(){
          this.isLoading=this.$store.state.accentColor;

          await this.$axios.all([this.getAvisos()]).then((response)=>{
              if(response[0].status==200){
                  if(response[0].data.code==141){
                      this.lastCounter=response[0].data.data.length;

                      this.avisosData=this.avisosData.concat(response[0].data.data);
                  }
              }
          }).catch((error)=>{
              console.log(error);
          });

          this.isLoading=false;
      },
        loadMore(){
            this.counterPagination++;
            this.loadAll();
        }
  },
  created(){
      this.loadAll();
  }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
.no-margin{
    margin: 0 !important;
}
</style>