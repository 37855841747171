<template>
    <div id="public-layout">
        <div id="wrapper">

            <!-- Main -->
                <div id="main">
                    <transition name="slide">
                        <router-view />
                    </transition>
                </div>

            <!-- Sidebar -->
                <div id="sidebar">
                    <div class="inner">
                        <!-- Menu -->
                            <nav id="menu">
                                <header class="major">
                                    <h2>Menu</h2>
                                </header>
                                <ul>
                                    <li><router-link to="/">Inicio</router-link></li>
                                    <li>
                                        <span class="opener">Novedades</span>
                                        <ul>
                                            <li><router-link to="/avisos">Avisos</router-link></li>
                                            <li><router-link to="/obras">Obras</router-link></li>
                                            <li><router-link to="/noticias">Noticias</router-link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <span class="opener">Municipalidad</span>
                                        <ul>
                                            <li><router-link to="/mision">Misión</router-link></li>
                                            <li><router-link to="/vision">Visión</router-link></li>
                                            <li><router-link to="/autoridades">Autoridades</router-link></li>
                                        </ul>
                                    </li>
                                    <li><router-link to="/municipio">Municipio</router-link></li>
                                    <li><router-link to="/informacion-publica">Información Pública</router-link></li>
                                    <li><router-link to="/sinacig">SINACIG</router-link></li>
                                    <!--<li>
                                        <span class="opener">Servicios</span>
                                        <ul>
                                            <li><router-link to="boleto-ornato">Boleto de ornato</router-link></li>
                                            <li><router-link to="iusi">IUSI</router-link></li>
                                        </ul>
                                    </li>-->
                                    <!--<li><router-link to="denuncias-ciudadanas">Denuncias ciudadanas</router-link></li>-->
                                    <li><router-link to="/comunicate">Comunícate</router-link></li>
                                </ul>
                            </nav>
                        <!-- Footer -->
                            <footer id="footer">
                                <p class="copyright">&copy; Municipalidad de Todos los Santos Cuchumatán, 2021.</p>
                            </footer>
                    </div>
                </div>

        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>
