<template>
        <div class="inner">

            <!-- Header -->
                <Header></Header>
            <viewer :images="getImagesViewer" hidden>
                <img v-for="src in getImagesViewer" :key="src" :src="src">
            </viewer>
            <!-- Banner -->
                <section id="banner">
                    <div class="content">
                        <header>
                            <h1>Trabajando Unidos para el Desarrollo del Municipio</h1>
                            <h4>Alcalde Martín Jiménez Jerónimo, Administración Municipal 2020-2024</h4>
                        </header>
                        <p>El municipio de Todos Santos Cuchumatán está localizado en la región noroccidente del territorio de Guatemala, en el departamento de Huehuetenango. Se encuentra a 316 kilómetros de la ciudad capital de Guatemala.
                        </p>
                        <ul class="actions">
                            <li><router-link to="municipio" class="button big">Ver más</router-link></li>
                        </ul>
                    </div>
                    <span class="image object">
                        <carousel centerMode :perPage="1" autoplay paginationColor="#fff" paginationActiveColor="#0083FF" :paginationPadding="5" loop :autoplayTimeout="5000">
                            <slide>
                                <img src="../assets/images/slide-1.jpg" class="img-slide" alt="">
                            </slide>
                            <slide>
                                <img src="../assets/images/slide-2.jpg" class="img-slide" alt="">
                            </slide>
                            <slide>
                                <img src="../assets/images/slide-3.jpg" class="img-slide" alt="">
                            </slide>
                        </carousel>
                    </span>
                </section>

            <!-- Section -->
                <section>
                    <header class="major">
                        <h2>Novedades</h2>
                    </header>
                    <div class="posts">
                        <article v-for="(noticia) in noticiasInicial" :key="noticia.idPublicacion">
                            <v-img :src="getImagen(noticia)" alt="" contain/>
                            <h3 class="no-margin">{{noticia.titulo}}</h3>
                            <p class="text-caption">{{getHumanDate(noticia.FechaTiempo)}}</p>
                            <p>{{getResumeContent(noticia.contenido)}}</p>
                            <ul class="actions">
                                <li><router-link class="button" :to="{name:'Noticias'}">Ver más</router-link></li>
                            </ul>
                        </article>
                        <article v-for="(aviso) in avisosInicial" :key="aviso.idPublicacion">
                            <v-img :src="getImagen(aviso)" alt="" contain/>
                            <h3 class="no-margin">{{aviso.titulo}}</h3>
                            <p class="text-caption">{{getHumanDate(aviso.FechaTiempo)}}</p>
                            <p>{{getResumeContent(aviso.contenido)}}</p>
                            <ul class="actions">
                                <li><router-link class="button" :to="{name:'Avisos'}">Ver más</router-link></li>
                            </ul>
                        </article>
                        <article v-for="(obra) in obrasInicial" :key="obra.idPublicacion">
                            <div class="image" >
                                <v-carousel height="300" hide-delimiter-background :show-arrows="false">
                                    <v-carousel-item v-for="(img,index) in obra.imagenes" :key="index">
                                        <v-img @click="onseeImages(obra)" :src="getImagenObra(img)" contain></v-img>
                                    </v-carousel-item>
                                </v-carousel>
                            </div>
                            <h3 class="no-margin">{{obra.titulo}}</h3>
                            <p class="text-caption">{{getHumanDate(obra.FechaTiempo)}}</p>
                            <p>{{getResumeContent(obra.contenido)}}</p>
                            <ul class="actions">
                                <li><router-link class="button" :to="{name:'Obras'}">Ver más</router-link></li>
                            </ul>
                        </article>
                    </div>
                </section>
                <section>
                    <header class="major">
                        <h2>Direcciones</h2>
                    </header>
                    <v-row justify="center">
                        <v-col cols="12" sm="12" md="10" lg="8" xl="6">
                            <h5>Todos Santos Cuchumatán, Huehuetenango.</h5>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30754.446161413958!2d-91.57988010514521!3d15.521730545414096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x858c43bc638a67b1%3A0x9eec50d5cfe350da!2sMunicipal%20Todos%20Santos%20Cuchamat%C3%A1n%20Regional%20Park!5e0!3m2!1sen!2sgt!4v1623811809341!5m2!1sen!2sgt" height="400" width="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                        </v-col>
                    </v-row>
                    <div class="justify-center">
                    </div>
                </section>
                <section>
                    <header>
                        <div class="icons">
                            <social-links></social-links>
                        </div>
                    </header>
                </section>
        </div>
</template>

<script>
import SocialLinks from '../components/SocialLinks.vue'
import { Carousel, Slide } from 'vue-carousel';
import Header from '../components/Header.vue';
import GetReadableDate from '../utils/date'

export default {
    computed:{
        getImagesViewer(){
            if(this.selectedObra)
                if(this.selectedObra.imagenes)
                    if(this.selectedObra.imagenes[0].img)
                        return this.selectedObra.imagenes.map(item=>item.img);
                    else
                        return this.selectedObra.imagenes;

            return "";
        }
    },
  components: { SocialLinks,Carousel,Slide, Header },
    data:function(){
        return ({
            noticiasInicial:[],
            avisosInicial:[],
            obrasInicial:[],
            selectedObra:""
        });
    },
    methods:{
        onseeImages(obra){
            this.selectedObra=obra;

            this.$viewerApi({
                options:{ "inline": true, "button": true, "navbar": true, "title": true, "toolbar": false, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true},
                images:this.getImagesViewer
            });
        },
        getImagenObra(img){
            if(img.img)
                return img.img;
            return img;
        },
        getImagen(publicacion){
            if(publicacion)
                if(publicacion.imagenes)
                    if(publicacion.imagenes.length>0)
                        return publicacion.imagenes[0];
            return "";
        },
        getHumanDate:GetReadableDate,
        getResumeContent(content){
            if(content)
                if(content.length>130)
                    return `${content.substring(0,130)}...`;
            return content;
        },
        loadNoticias(){return this.$axios.get(`noticias/PublicNoticias.php?page=1`);},
        loadAvisos(){return this.$axios.get(`avisos/PublicAvisos.php?page=1`)},
        loadObras(){return this.$axios.get(`obras/PublicObras.php?page=1`)},
        async loadAll(){
            await this.$axios.all([this.loadNoticias(),this.loadAvisos(),this.loadObras()]).then((response)=>{
                if(response[0].status==200&&response[1].status==200&&response[2].status==200){
                    this.noticiasInicial=response[0].data.data;
                    this.avisosInicial=response[1].data.data;
                    this.obrasInicial=response[2].data.data;
                }
            }).catch((error)=>{
                console.log(error);
            });
        }
    },
    created(){
        this.loadAll();
    }
}
</script>
<style>
.no-margin{
    margin: 0 !important;
}

.img-slide{
    max-height: 500px !important;
    object-fit: contain !important;
}
.img-logo{
    height: 125px !important;
    object-fit: contain !important;
}

#header > img{
    flex-grow: 0.2 !important;
}
#header *{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
</style>