<template>
    <div class="inner">
        <!-- Content -->
            <section>
                <br>
                <br>
                <div v-if="$router.currentRoute.name=='SINACIG-YEAR'">
                    <v-app id="sinacig">
                        <v-row class="pa-0" justify="center">
                            <v-col v-for="(opcion,index)  in opciones.filter(item=>item.year.some(item2=>item2==$route.params.year))" :key="index" cols="12" sm="12" md="4" xl="2">
                                <v-card rounded="xl" hover ripple @click="onClickOpcion(opcion.pathTo)" height="275">
                                    <v-sheet height="150" color="transparent">
                                        <v-card-title class="text-md-h5 font-weight-bold justify-center">
                                            {{opcion.text}}
                                        </v-card-title>
                                    </v-sheet>
                                    <v-card-text>
                                        <v-row no-gutters justify="center">
                                            <v-icon class="pa-0" size="75" color="black">{{opcion.icon}}</v-icon>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-app>
                </div>
                <router-view />
            </section>
            <br>
            <br>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import SocialLinks from '../../components/SocialLinks.vue'
export default {
  components: {  SocialLinks },
  computed:{
  },
  data:function(){
      return ({
          opciones:[
                {
                    pathTo:{name:'Acuerdos'},
                    text:"Acuerdos",
                    icon:"menu_book",
                    year:[2021]
                },
                {
                    pathTo:{name:'Capacitacion'},
                    text:"Capacitación",
                    icon:"psychology",
                    year:[2021]
                },
                {
                    pathTo:{name:'Documentos'},
                    text:"Documentos",
                    icon:"menu_book",
                    year:[2021,2022]
                },
                {
                    pathTo:{name:'Informes'},
                    text:"Informes",
                    icon:"feed",
                    year:[2021,2022]
                },
                {
                    pathTo:{name:'Codigo de ética, Reglamento, Politicas anticorrupción'},
                    text:"Codigo de ética, Reglamento, Politicas anticorrupción",
                    icon:"gavel",
                    year:[2021]
                },

            ]
      })
  },
  methods:{
      onClickOpcion(path){
          console.log(path);
          this.$router.push(path);
      }
  }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
</style>