<template>
    <div class="inner">
        <!-- Header -->
            <Header/>

        <!-- Content -->
            <section>
                <header class="main">
                    <h1>Autoridades</h1>
                </header>
                <div class="row" id="autoridades">
                    <div v-for="(autoridad,index) in autoridades" :key="index" class="col-6-xsmall col-4-small col-4-medium col-3-large" align="center">
                        <div class="round-avatar">
                            <img src="../../assets/images/person-icon.png" class="image fit hola" :width="getSize" :height="getSize" alt="">
                        </div>
                        <h5>
                            {{autoridad.nombre}}
                            <p>
                                {{autoridad.puesto}}
                            </p>
                        </h5>
                    </div>
                </div>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
export default {
  components: {  Header, SocialLinks },
  computed:{
      getSize(){
          if(this.$vuetify.breakpoint.xsOnly){
              return 85
          }else if(this.$vuetify.breakpoint.smAndUp){
              return 100;
          }else if(this.$vuetify.breakpoint.lgAndUp){
              return 115;
          }
          return 115;
      }
  },
  data:function(){
      return ({
          autoridades:[
              {nombre:"Martín Jiménez Jerónimo",puesto:"Alcalde Municipal"},
              {nombre:"Atanacio Mendoza Pablo",puesto:"Concejal I"},
              {nombre:"Nicolás Pablo Pérez Concejal",puesto:"Concejal II"},
              {nombre:"Alberto Pablo Calmo Concejal",puesto:"Concejal III"},
              {nombre:"Lucío Matías Figuera Martínez",puesto:"Concejal IV"},
              {nombre:"Jorge Pablo Gómez",puesto:"Concejal V"},
              {nombre:"Juan Pablo Mendoza",puesto:"Síndico I"},
              {nombre:"Abilio Calmo Martín",puesto:"Síndico II"},
          ]
      })
  }
}
</script>
<style scoped>
.round-avatar{
    border-radius: 50%;
    max-height: 115px;
    max-width: 115px;
}
.hola{
    object-fit: contain;
}
</style>