<template>
    <v-app id="inicio">
        <v-row class="pa-0 ma-0" justify="center" align="center">
            Inicio
        </v-row>
    </v-app>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>