<template>
    <div class="inner">
        <!-- Header -->
            <Header/>
        <!-- Content -->
            <section>
                <header class="main">
                    <h1><a href="/informacion-publica">Información Pública</a></h1>
                </header>
                <div v-if="$router.currentRoute.name=='Informacion Publica'">
                    <v-app id="hola">
                        <div v-for="decreto in decretos" :key="decreto.idDecreto">
                            <v-row>
                                <h2>{{decreto.decreto}}</h2>
                            </v-row>
                            <v-row no-gutters>
                                <p>{{decreto.descripcion}}</p>
                            </v-row>
                            <br>
                            <v-row no-gutters>
                                <v-col>
                                    <v-list>
                                        <v-list-item v-for="inciso in decreto.categorias" :key="inciso.idCategoria">
                                            <v-list-item-content class="pa-2">
                                                <v-card light rounded="xl" hover @click="()=>{goToInciso(decreto,inciso)}" ripple>
                                                    <v-row no-gutters class="pa-4 text-xs-body-2 text-sm-body-1 text-lg-subtitle-1 font-weight-medium">{{inciso.categoria}}</v-row>
                                                </v-card>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                            <br>
                            <br>
                            <br>                        
                            <v-divider></v-divider>
                        </div>
                    </v-app>
                </div>
                <router-view/>
            </section>
            <section>
                <header>
                    <div class="icons">
                        <social-links></social-links>
                    </div>
                </header>
            </section>
    </div>
</template>
<script>
import Header from '../../components/Header.vue'
import SocialLinks from '../../components/SocialLinks.vue'
export default {
  components: {  Header, SocialLinks },
  computed:{
  },
    data:function(){
        return ({
            years:[],
            decretos:[],
            textosDecretos:[
                "Artículo 10. Información pública de oficio. Los Sujetos Obligados deberán mantener, actualizada y disponible, en todo momento, de acuerdo con sus funciones y a disposición de cualquier interesado, como mínimo, la siguiente información, que podrá ser consultada de manera directa o a través de los portales electrónicos de cada sujeto obligado: ",
                "Artículo 17 Ter. Informes en Sitios Web y Comisiones de Trabajo del Congreso de la República. Los sujetos obligados a las disposiciones de la presente Ley, con el propósito de brindar a la ciudadanía guatemalteca transparencia en la gestión pública, además de cumplir con la entrega de información y documentación con la periodicidad que establece esta Ley, deberán mostrar y actualizar por lo menos cada treinta (30) días, a través de sus sitios web de acceso libre, abierto y gratuito de datos, y por escrito a las Comisiones de Probidad, de Finanzas Públicas y Moneda y a la Extraordinaria Nacional por la Transparencia, del Congreso· de la República de Guatemala, la información y documentación siguiente, sin perjuicio de lo que al respecto establece la Ley de Acceso a la Información Pública: "
            ]
        })
    },
    methods:{
        goToInciso(decreto,inciso){
            this.$router.push({name:"Inciso",params:{decreto:decreto.idDecreto,inciso:inciso.idCategoria}});
        },
        toYear(year){
            this.$router.push({name:"Info Publica Año",params:{year:year}});
        },
        loadCategories(){return this.$axios.get("decretos/PublicDecretos.php");},
        loadAll(){
            this.$axios.all([this.loadCategories()]).then((response)=>{
                if(response[0].status==200)
                    if(response[0].data.code==141){
                        this.decretos=response[0].data.data;
                        this.mappingDescriptions();
                    }
            }).catch((error)=>{
                console.log(error);
            });
        },
        mappingDescriptions(){
            for(var counter=0;counter<this.decretos.length;counter++){
                this.decretos[counter]["descripcion"]=this.textosDecretos[counter];
            }
        }
    },
    created(){
        this.loadAll();
        for(var year=2020;year<=new Date().getFullYear();year++){
            this.years.push(year);
        }
    }
}
</script>
<style scoped>
::v-deep .v-application--wrap {
    min-height: 0vh;
  }
  .inline{
      display: inline;
  }
</style>