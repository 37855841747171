<template>
    <ul class="icons">
        <li><a href="https://www.facebook.com/Municipalidad-De-Todos-Santos-Cuchumat%C3%A1n-Administraci%C3%B3n-2020-2024-113105280372667" class="icon brands fa-facebook-f" target="_blank"><span class="label">Facebook</span></a></li>
    </ul>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>