<template>
    <v-app id="noticias">
        <v-row v-if="!isLoaded" class="pa-0 ma-0" justify="center" align="center">
            <v-col cols="10" sm="7" md="5" lg="4" xl="3">
                <v-progress-linear :color="$store.state.accentColor" indeterminate></v-progress-linear>
            </v-col>
        </v-row>
        <v-container fluid>
            <v-row  class="pa-0 ma-0" justify="center">
                <v-col cols="6" sm="4" md="2" lg="2" xl="2">
                    <v-select
                    :item-color="$store.state.accentColor"
                    :color="$store.state.accentColor"
                    :items="years"
                    v-model="year"
                    label="Año"
                    solo
                    @change="onYearChange"
                    ></v-select>
                </v-col>
                <v-col cols="6" sm="4" md="2" lg="2" xl="2">
                    <v-select
                    :item-color="$store.state.accentColor"
                    :color="$store.state.accentColor"
                    :items="months"
                    v-model="month"
                    label="Mes"
                    solo
                    @change="onMonthChange"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row v-if="isLoaded" justify="center">
                <v-dialog v-model="showDeleteDialog" max-width="500" scrollable>
                    <v-card :loading="savingLoader" :disabled="savingLoader">
                        <v-card-title>Confirmación</v-card-title>
                        <v-card-text>
                            ¿Desea eliminar la noticia '{{selectedNoticia.titulo}}'?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="showDeleteDialog=false" text>No</v-btn>
                            <v-btn @click="deleteNoticia(selectedNoticia.idPublicacion)" text>Si</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="showNoticiaDialog" max-width="500" scrollable>
                    <v-card>
                        <v-card-title>Detalle de noticia</v-card-title>
                        <v-card-text>
                            <v-img :src="selectedNoticia!=''?selectedNoticia.imagenes.length>0?selectedNoticia.imagenes[0]:'':''"></v-img>
                            <p class="headline mb-2">{{selectedNoticia.titulo}}</p>
                            <p>{{selectedNoticia!=''?convertToReadeableDate(selectedNoticia.FechaTiempo):""}}</p>
                            <p>{{selectedNoticia.contenido}}</p>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="showNoticiaDialog=false" text>Ok</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="newNoticiaDialog" max-width="400" persistent scrollable>
                    <v-card :loading="savingLoader" :disabled="savingLoader">
                        <v-card-title>
                            Nueva noticia
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="newNoticiaForm">
                                <br>
                                <v-text-field
                                    label="Título de noticia"
                                    :color="$store.state.accentColor"
                                    v-model="noticiaForm.titulo"
                                    :rules="noticiaForm.tituloRules"
                                    counter="100"
                                    autocomplete="off"
                                >

                                </v-text-field>
                                <v-file-input 
                                prepend-icon=""
                                    show-size counter 
                                    label="Seleccione imágenes"
                                    v-model="imgsToUpload"
                                    accept="image/*"
                                    :color="$store.state.accentColor"
                                    multiple
                                ></v-file-input>
                                <v-textarea :rules="noticiaForm.contenidoNoticiaRules" 
                                v-model="noticiaForm.contenidoNoticia" 
                                label="Contenido de noticia" 
                                :color="$store.state.accentColor"
                                counter="5000"
                                autocomplete="off"
                                >

                                </v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="newNoticiaDialog=false">Cancelar</v-btn>
                            <v-btn text @click="saveNoticia()">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="editNoticiaDialog" max-width="400" persistent scrollable>
                    <v-card :loading="savingLoader" :disabled="savingLoader">
                        <v-card-title>
                            Edición de noticia
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="editNoticiaForm">
                                <br>
                                <v-text-field
                                    label="Título de noticia"
                                    :color="$store.state.accentColor"
                                    v-model="noticiaForm.titulo"
                                    :rules="noticiaForm.tituloRules"
                                    counter="100"
                                    autocomplete="off"
                                >

                                </v-text-field>
                                <v-file-input 
                                prepend-icon=""
                                    show-size counter 
                                    label="Seleccione imágenes"
                                    v-model="imgsToUpload"
                                    accept="image/*"
                                    :color="$store.state.accentColor"
                                    multiple
                                    @change="onselectImages"
                                ></v-file-input>
                                <v-row no-gutters class="text-subtitle-1 font-weight-bold" justify="center">Imagenes existentes</v-row>
                                <v-list dense class="pa-0" v-if="noticiaForm.imagenes.length>0">
                                    <v-list-item v-for="(imagen,index) in noticiaForm.imagenes" :key="index">                                        
                                        <v-list-item-content>
                                            <v-list-item-subtitle class="wrap-text">
                                                {{getImagenName(imagen)}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn icon small @click="deleteOldImgs(index)"><v-icon small>clear</v-icon></v-btn>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                                <v-textarea :rules="noticiaForm.contenidoNoticiaRules" 
                                v-model="noticiaForm.contenidoNoticia" 
                                label="Contenido de noticia" 
                                :color="$store.state.accentColor"
                                counter="5000"
                                autocomplete="off"
                                >

                                </v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="editNoticiaDialog=false">Cancelar</v-btn>
                            <v-btn text @click="updateNoticia()">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-col v-for="(noticia) in noticias" :key="noticia.idPublicacion" cols="12" xs="12" sm="12" md="9" lg="6" xl="5">
                    <v-card outlined>
                        <v-btn icon style="position:absolute; right:0px" v-if="$store.state.UserLevel=='1'" @click="showDeleteDialog=true;selectedNoticia=noticia;"><v-icon>clear</v-icon></v-btn>
                        <div v-if="$vuetify.breakpoint.xsOnly">
                            <v-img :src="noticia.imagenes.length>0?noticia.imagenes[0]:''" height="200"></v-img>
                                <v-card-title>
                                    {{noticia.titulo}}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{getHumanDate(noticia.FechaTiempo)}}
                                </v-card-subtitle>
                                <v-card-text>
                                    {{shortenedContent(noticia.contenido)}}
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text rounded @click="selectedNoticia=noticia;showNoticiaDialog=true;">Ver detalles</v-btn>
                                    <v-btn icon small @click="onedit(noticia)" v-if="$store.state.UserLevel=='1'"><v-icon small>edit</v-icon></v-btn>
                                </v-card-actions>
                        </div>
                        <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex flex-no-wrap">
                            <v-avatar
                                size="200"
                                tile
                            >
                                <v-img :src="noticia.imagenes.length>0?noticia.imagenes[0]:''"></v-img>
                            </v-avatar>
                            <div style="width:100%;">
                                <v-card-title>
                                     {{noticia.titulo}}
                                </v-card-title>
                                <v-card-subtitle>
                                    {{convertToReadeableDate(noticia.FechaTiempo)}}
                                </v-card-subtitle>
                                <v-card-text class="py-0">
                                    {{shortenedContent(noticia.contenido)}}
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn small outlined rounded @click="selectedNoticia=noticia;showNoticiaDialog=true;">Ver detalles</v-btn>
                                    <v-btn icon small @click="onedit(noticia)" v-if="$store.state.UserLevel=='1'"><v-icon small>edit</v-icon></v-btn>
                                </v-card-actions>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-btn      
                    :color="$store.state.accentColor"              
                    fixed
                    fab
                    bottom
                    right
                    @click="newNoticiaDialog=!newNoticiaDialog"
                >
                    <v-icon color="white">add</v-icon>
                </v-btn>
                <v-snackbar
                    v-model="snackbarObject.snackbar"
                    :color="snackbarObject.snackbarColor"
                >
                    {{ snackbarObject.snackbarText }}
                </v-snackbar>
                
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
//import BlogItem from '../../CommonComponents/BlogItem'
import GetReadableDate from '../../utils/date';
export default {
    components:{
        //BlogItem
    },
    watch:{
        newNoticiaDialog(newval,oldval){
            oldval;
            if(!newval)
                this.$refs.newNoticiaForm.reset();
        },
        editNoticiaDialog(newval,oldval){
            oldval;
            if(!newval)
                this.$refs.editNoticiaForm.reset();
        }
    },
    data:()=>({
        year:new Date().getFullYear(),
        month:"",
        years:[],
        selectedNoticia:"",
        months:[
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        isLoaded:false,
        savingLoader:false,
        menu:false,
        newNoticiaDialog:false,
        editNoticiaDialog:false,
        showNoticiaDialog:false,
        showDeleteDialog:false,
        snackbarObject:{
            snackbar:false,
            snackbarText:"",
            snackbarColor:"",
        },
        imgsToUpload:[],
        noticiaForm:{
            idPublicacion:"",
            titulo:"",
            tituloRules:[
                v => !!v || 'Título es requerido',
                v => (v && v.length <= 100) || 'Título debe ser menor o igual a 50',
            ],
            fecha: "",
            fechaRules:[
                v => !!v || 'Fecha es requerida',
                v => (v && v.length <= 50) || 'La fecha es requerida.',
            ],
            contenidoNoticia: "",
            contenidoNoticiaRules: [
                v => !!v || 'Contenido de noticia es requerido',
                v => (v && v.length <= 5000) || 'El contenido de la noticia no debe contener más de 5000 caracteres.',
            ],
            imagenes:[]
        },
        noticias:[]
    }),
    methods:{
        deleteOldImgs(index){
            this.noticiaForm.imagenes.splice(index,1);
        },
        onselectImages(val){
            if(val){
                val.forEach(item=>{
                    if(this.noticiaForm.imagenes.some(item2=>item2.includes(item.name))){
                        this.imgsToUpload=[];
                    }
                });
            }
        },
        getImagenName(imagen){
            var splitName=imagen.split("/");

            return splitName[splitName.length-1];
        },
        async onedit(noticia){
            this.editNoticiaDialog=true;
            await this.loadNoticia(noticia);
        },
        getHumanDate: GetReadableDate,
        convertToReadeableDate(unparsedDateTime){
            let onlyDate=unparsedDateTime.split(" ");
            let splittedDate=onlyDate[0].split("-");

            return splittedDate[2]+" de "+this.months[parseInt(splittedDate[1])-1]+" "+splittedDate[0];
        },
        shortenedContent(contentString){
            return contentString.substring(0,50)+"...";
        },
        onYearChange(anio){
            this.loadNoticias(anio,this.months.indexOf(this.month)+1)
        },
        onMonthChange(mes){
            this.loadNoticias(this.year,this.months.indexOf(mes)+1)
        },
        async loadNoticias(anio,mes){
            this.isLoaded=false;

            await this.$axios.get(`noticias/Noticias.php?year=${anio}&month=${mes}`).then(response=>{
                if(response.status==200){
                    if(response.data.code==141){
                        this.noticias=response.data.data;
                    }
                }
            }).catch(error=>{
                error;
                this.showSnackbar(`Ha ocurrido un error grave al cargar los documentos, si el error persiste contacte a soporte.`,"red");
            });

            this.isLoaded=true;
        },
        async loadNoticia(noticia){
            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.get(`noticias/Noticias.php?noticia=${noticia.idPublicacion}`).then(response=>{
                if(response.status==200){
                    if(response.data.code==141){
                        this.initializeNoticia(response.data.data[0]);
                    }
                }
            }).catch(error=>{
                error;
                this.showSnackbar(`Ha ocurrido un error grave al cargar los documentos, si el error persiste contacte a soporte.`,"red");
            });

            this.savingLoader=false;
        },
        initializeNoticia(data){
            this.noticiaForm.idPublicacion=data.idPublicacion;
            this.noticiaForm.titulo=data.titulo;
            this.noticiaForm.contenidoNoticia=data.contenido;
            this.noticiaForm.imagenes=data.imagenes;
        },
        async updateNoticia(){
            if(this.$refs.editNoticiaForm.validate()){
                let contendForm=new FormData();

                contendForm.append("titulo",this.noticiaForm.titulo);
                contendForm.append("contenido",this.noticiaForm.contenidoNoticia);
                contendForm.append("imagenes",JSON.stringify(this.noticiaForm.imagenes));

                for(let index=0;index<this.imgsToUpload.length;index++){
                    contendForm.append(index,this.imgsToUpload[index]);
                }

                this.savingLoader=this.$store.state.accentColor;

                await this.$axios.post(`noticias/Update.php?noticia=${this.noticiaForm.idPublicacion}`,contendForm).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 821:{
                                this.showSnackbar(`La noticia ha sido actualizada correctamente.`,"green");

                                this.loadNoticias(this.year,this.months.indexOf(this.month)+1);
                                this.imgsToUpload=[];
                                this.$refs.editNoticiaForm.reset();
                            }break;
                            case 820:{
                                this.showSnackbar(`Ha ocurrido un error al actualizar la noticia, si el error persiste contacte a soporte.`,"");
                            }break;
                        }
                    }
                }).catch(error=>{
                    error;
                    this.showSnackbar(`Ha ocurrido un error grave al actualizar la noticia, si el error persiste contacte a soporte.`,"red");
                });

                this.savingLoader=false;
                this.editNoticiaDialog=false;
            }
        },
        async saveNoticia(){
            if(this.$refs.newNoticiaForm.validate()){
                let contendForm=new FormData();

                contendForm.append("titulo",this.noticiaForm.titulo);
                this.noticiaForm.fecha=`${this.year}-${this.months.indexOf(this.month)+1}-${new Date().getDate()}`;
                contendForm.append("fecha",this.noticiaForm.fecha);
                contendForm.append("contenido",this.noticiaForm.contenidoNoticia);

                for(let index=0;index<this.imgsToUpload.length;index++){
                    contendForm.append(index,this.imgsToUpload[index]);
                }

                this.savingLoader=this.$store.state.accentColor;

                await this.$axios.post("noticias/Create.php",contendForm).then(response=>{
                    if(response.status==200){
                        switch(response.data.code){
                            case 821:{
                                this.showSnackbar(`La noticia ha sido agregada correctamente.`,"green");

                                this.loadNoticias(this.year,this.months.indexOf(this.month)+1);
                                this.imgsToUpload=[];
                                this.$refs.newNoticiaForm.reset();
                            }break;
                            case 820:{
                                this.showSnackbar(`Ha ocurrido un error al guardar la noticia, si el error persiste contacte a soporte.`,"");
                            }break;
                        }
                    }
                }).catch(error=>{
                    error;
                    this.showSnackbar(`Ha ocurrido un error grave al guardar la noticia, si el error persiste contacte a soporte.`,"red");
                });

                this.savingLoader=false;
                this.newNoticiaDialog=false;
            }
        },
        async deleteNoticia(id){
            this.savingLoader=this.$store.state.accentColor;

            await this.$axios.delete(`noticias/Delete.php?noticia=${id}`).then(response=>{
                if(response.status==200){
                    switch(response.data.code){
                        case 140:
                            this.showSnackbar(`La noticia ya ha sido eliminada.`);
                            break;
                        case 141:
                            this.showSnackbar(`La noticia ha sido eliminada correctamente.`,"green");
                            this.loadNoticias(this.year,this.months.indexOf(this.month)+1);
                            break;
                    }
                }
            }).catch(error=>{
                if(error.response!=null){
                        if(error.response.status==404){
                            if(error.response.data!=null){
                                if(error.response.data.code==29||error.response.data.code==25){
                                    this.$cookies.remove("AUTH_TOKEN");
                                    this.$router.replace({name:"Login"});           
                                }
                            }
                        }   
                    }

                this.showSnackbar(`Ha ocurrido un error grave al eliminar la noticia, si el error persiste contacte a soporte.`,"red");
            });

            this.savingLoader=false;
            this.showDeleteDialog=false;
        },
        showSnackbar(text,color){
            this.snackbarObject.snackbar=false;
            
            this.snackbarObject.snackbarText=text;
            
            this.snackbarObject.snackbarColor=color;
            this.snackbarObject.snackbar=true;
        }
    },
    created(){
        for(var year=2020;year<=new Date().getFullYear();year++){
            this.years.push(year);
        }

        this.month=this.months[new Date().getMonth()];

        this.loadNoticias(new Date().getFullYear(),new Date().getMonth()+1);
    }
}
</script>
<style scoped>
.wrap-text {
  white-space: unset !important;
}
</style>